.btn-bottom-sticky {
  .outlined {
  border: 1px solid #b8b8b8 !important;
  color: black !important;
  font-weight: 500;
}
}
.btn-bottom-sticky
{
  // position: sticky!important;
  bottom: 0!important;
  left: 0!important;
  right: 0!important;
  align-items: baseline!important;
  background-color: #f9f9f9;
  border-top:1px solid #b8b8b8;
}
.btn-action
{
  width:max-content;
}
.Purchase-products-table-popup tr
{
  border-bottom: 1px solid #8080804d;
}
.Purchase-products-table-popup>thead>tr>th
{
 background: #fbfcfd;
  color: #000;
  font-weight: 800;
  font-size: 14px;
  white-space: nowrap;
  
}

.form-wrapper-add {
  width: 100%;
  // text-align: center;
  margin: 0px 0 !important;
  // display: flex;
  th
  .name-wrapper1 {
    // width: fit-content;
    // margin: auto;
    margin: 0 30px;
  }
  .layout-flex {
    display: flex;
    .input-field-layout1 {
      width: 100%;
    }
  }

  .save-cancel-btn {
    margin: 10px;
    text-align: center;
  }

  .map-col-wrapper {
    width: 100%;
  }
}

.switch-color {
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #ff632b;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ff632b;
  }
}

.toogle-add {
  display: flex;
  align-items: center;
}
.toogle-hide {
  display: none;
}

.country-label {
  width: fit-content;
  color: #898989;
  margin: 10px 0 0;
  font-size: 14px;
  font-family: 'Rubik';
}

.country-select {
  width: 38%;
}

.text-header1 {
  color: #070707 !important;
  font-size: larger;
  font-family: 'Rubik';
  background-color: #f5f5f5 !important;
  font-weight: 500;
  display: flex;
  margin: 0px !important;
}
.return-order {
  margin: 10px 0;
  line-height: 2;
  color: gray;
}
.multiselect-hide {
  display: none;
}

.multi-select-dropdown {
  .chip {
    background-color: #ff632b;
  }
}

.active-days-selection-wrapper {
  margin: 10px 0 0 0;
  .heading-working-days {
    font-size: 14px;
    font-family: 'Rubik';
    margin-right: 15px;
    color: #898989;
    padding: 10px 0;
  }

  .custom-days-wrapper {
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .pop-po-main-button {
    display: flex;
    justify-content: space-between;
  }
  .purchase-order-button {
    display: flex !important;
    margin: 14px !important;
    justify-content: space-between !important;
    padding-left: 20px!important;
    padding-right: 30px!important;
  }

   .text-header2 {
    background: #F5F5F5 ;
    margin: 0;
    font-size: 14px;
    color: #000 !important;
    padding: 10px 10px;
    box-shadow: 0px 0px 3px #a5a5a5;
}


}
.addware-popup {
  .MuiDialog-scrollPaper {
      justify-content: flex-end;
      margin-top: 60px;
  }
  .text-header1{
    align-items: center;
    padding: 0 10px;
    justify-content: left;
  }

  .MuiDialog-paperWidthSm {
      max-width: 100%;
  }

  .MuiDialog-paperScrollPaper {
      display: block;
      max-height: 100%;
      height: 100vh;
      margin: 0;
      width: 40vw;
  }
  .MuiBackdrop-root {
      background: transparent;
  }
}