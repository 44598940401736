.terms-and-conditions{
    display: flex;
    align-items: center;
    font-family: 'Lato';
    .btn-link {
        margin-left: 5px;
    }
}

.registration-seller {
    text-align: center;
}

.btn-signup  {
    background-color: #ff8e33 !important;
    margin: 10px 0;
}
.account-code-hide{
    display: none;
}
.MuiInputLabel-formControl {
    font-size: 12.5px;
    font-family: 'Lato';
    &.MuiFormLabel-filled{
        font-size: 14px;
        color: #ff8e33 !important;
    }
    &.Mui-focused {
      font-size: 14px;
      color: #ff8e33 !important;
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ff8e33  !important;
    }

    .MuiFormLabel-root.Mui-focused {
        color: #ff8e33;
    }

    .account-field{
        margin: 10px 0px !important;
        .MuiInputLabel-formControl {
            font-size: 13px;
            
        }
    } 

    .type-dropdown{
        display: none;
        .MuiFormControl-root{

            width: 100%;
        
        }
        .MuiInputBase-root {
            height: 45px;
        }
    }

    .view-terms-and-conditions {
        height: 60vh;
        min-width: 35vw;
        .pdf-viewer-wrapper {
             width: 100%;
            height: 100%;
        }
    }