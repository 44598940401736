$shadow: 0px 1px 5px #00000029;
$radius: 10px;
$padding: 10px;



.payments-data-table {
  margin: 10px 0;
  background-color: #fff;
  
  .payments-table {
    box-shadow: 0px 1px 5px #00000029;
    tbody {
      tr {
        .payment-status-info {
          .pending {
            margin: 0 auto;
            color: #ffffff;
            background: #ff9800;
            max-width: 100px;
            width: 100px;
            border-radius: 5px;
            padding: 3px;
            text-align: center;
          }
          .initiated {
            margin: 0 auto;
            color: #008000;
            background: #00800052;
            max-width: 100px;
            width: 100px;
            border-radius: 5px;
            padding: 3px;
            text-align: center;
          }
        }
      }
    }
  }
}

.btn-icon {
  cursor: pointer;
}

.hide-payment-details-slider {
    right: -25vw !important;
}

.payment-details-slider {
  height: 100vh;
  position: fixed;
  top: 0;
  background: #fff;
  width: 25vw;
  right: 0;
  box-shadow: 0px 1px 5px #00000029;
  transition: all .5s ease-out;
  .slider-content {
    margin: 0 15px;
    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    
        .header {
          margin: 0;
          text-align: center;
        }
        .close-icon {
          cursor: pointer;  
        }
      }
  }
}

.action-col {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.payment-information-card {
  margin: 10px 0;
  box-shadow: $shadow;
  border-radius: $radius;
  padding: $padding;
  .payment-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      margin: 7px 0;
      font-size: 11px;
    }
    .payment-status{
      color: #008000;
      background: #00800052;
      max-width: 100px;
      width: 100px;
      border-radius: 5px;
      padding: 3px;
      text-align: center;
    }
  }
}

.payments-update-form {
  .input-label-input-field {
    padding: 2px 15px;
  }
  .payment-date-picker{
    .text-label{
      margin: 0;
      font-size: 16px;
      color: #818181;
      padding-left: 5px
    }
    input {
      height: 40px;
      width: 95%;
      margin: 4px;
      border-radius: 6px;
      border: 1px solid #069800;
    }

    input[type="date"]:disabled {
      background: #dddddd;
      border:transparent ;
    }
    
  }
}

