.seller-account-status-active {
    margin: 0;
    border: 1px solid #33ff9938;
    padding: 3px;
    border-radius: 10px;
    color: #2b6c54;
    font-family: "Lato";
    font-size: 11px;
    background: #d1f9e5;
    font-weight: 900;
    text-transform: uppercase;
   }
 
   .seller-account-status-in-active {
    margin: 0;
    border: 1px solid #ff8e3352;
    padding: 3px;
    border-radius: 10px;
    color: #ff8e33;
    font-family: "Lato";
    font-size: 11px;
    background: #ff8e331c;
    font-weight: 900;
    text-transform: uppercase;
    width: 80px;

   }
