.warehouse-history-page {
    margin: 0 25px;
  .history-heading {
    color: #090a0c;
    font-size: 16px;
    margin: 0;
    font-weight: 800;
  }

  .history-table {
      margin: 20px 0;
      thead {
        tr{
            th{
                color: #000;
                font-weight: 700;
                font-size: 13px;;
            }
        }
      }
  }
  .captions {
    font-size: 13px;
    text-align: end;
    color: #746764;
  }

  .pagination-zone {
    display: flex;
    justify-content: end;
  }
}
