.icon-input-file-wrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    border: 1px solid #e8e9ec;
    border-radius: 5px;
    height: 40px;
    position: relative;
    padding: 0 15px;
    .drag-drop-icon {
        color: #f0f2f8;
        margin-right: 15px;
    }
    .Drap-drop-content {
        font-size: 10px;
        color: #0080009e;
    }

    p {
        margin: 0;
    }
    .drag-and-drop-wrapper {
        width: fit-content;
        // height: 40px;
        // border: 1px solid #F5F5F5;
        border: 0;
        margin: 0;
        background: #f5f5f5;
        padding: 7px 18px;
        cursor: pointer;
        position: absolute;
        right: 0;

        .drag-n-drop-text {
            font-family: 'Lato';
            font-size: 12px;
            color: #3b86ff;
        }
    }
    .drag-and-drop-wrapper-profile {
        border: 1px dotted;

        background: #93939387;
        cursor: pointer;
        .drag-n-drop-text {
            font-family: 'Lato';
            font-size: 14px;
        }
    }
    .view-document-btn-wrapper {
        width: fit-content;

        border: 0;
        margin: 0;
        background: #f5f5f5;
        padding:12px 21px;
        cursor: pointer;
        position: absolute;
        right: 0;
        font-family: 'Lato';
        font-size: 12px;
        color: #3b86ff;
    }
}
