.btn-action {
    background: #f25822;
    font-family: 'Lato';
    outline: transparent;
    border: none;
    height: 30px;
    padding: 0px 15px;
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: fit-content;
  }

  .outlined {
    background: #FFF;
    font-family: 'Lato';
    outline: transparent;
    border: 1px solid #ff632b;
    height: 30px;
    padding: 0px 15px;
    color: #ff632b;
    border-radius: 3px;
   
    cursor: pointer;
  }

  select.seller-remove-icon{
   -moz-appearance: none;
   -webkit-appearance: none;
  }

  select::-ms-expand.seller-remove-icon {
    display: none;
  }

  .status-dropdown {
    width: 100px;
    border-radius: 5px !important;
    outline: none;
    text-align-last: center;
    padding: 5px 6px;
    font-size: 14px !important;
    background: #f2fff2;
    color: #606060;
    border: 0.5px solid #41b13d;

    &.danger {
        background: #fff0f0;
        border: 0.5px solid #ffbcbc;
    }

    option {
        background-color: #fff;
        font-size: 14px;
    }
}

.delisted-dropdown {
    width: 100px;
    border-radius: 5px !important;
    outline: none;
    text-align-last: center;
    padding: 5px 7px;
    font-size: 13px !important;
    background: #fff0f0;
    color: #606060;
    border: 0.5px solid #ffbcbc;

    &.danger {
        background: #fff0f0;
        border: 0.5px solid #ffbcbc;
    }

    option {
        background-color: #fff;
        font-size: 14px;
    }
}