.Inventory-table-body {
    width: 100%;
    .filter-head{
        display: flex;
        align-items: center;
        justify-content: left;
        height: 'auto';
        border: 1px solid #f5f5f5;
        background-color: #ffffff;
        box-shadow: 0px 1px 5px #00000029;
        padding: 10px 0;
        flex-wrap: wrap;
    }
    .inventory-products-table {
        position: relative;
        border: 1px solid #f5f5f5;
        background-color: #ffffff;
        box-shadow: 0px 1px 5px #00000029;

        .product-col {
            display: flex;

            .pimg-wrap {
                width: 65px;
                height: 65px;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                margin-right: 19px;
                img.product-img {
                    width: auto;
                    height: 50px;
                    object-fit: contain;
                }
            }

            .pcontent-wrap {
                display: flex;
                align-items: center;
                justify-content: left;
                line-height: 20px;
                word-break: break-word;
                color: #121212;
            }
        }
        .status-col {
            .badge-wrap {
                display: inline-flex;
                padding: 4px 10px;
                background: #f2fff2;
                border: 0.5px solid #41b13d;
                border-radius: 100px;
                color: #606060;
                font-size: 11px;
            }
        }

        .status-approved {
            margin: 0 auto;
            width: 50px;
            padding: 3px 20px;
            border: 0.5px solid #41b13d;
            border-radius: 12px;
            background: #f2fff2;
            font-size: 11px;
        }
        .status-disapproved {
            margin: 0 auto;
            width: fit-content;
            padding: 3px 20px;
            border: 0.5px solid #ffbcbc;
            border-radius: 12px;
            background: #fff0f0;
            font-size: 11px;
        }
    }
    .all-products-pagination {
        margin-top: 50px;
        .MuiPagination-ul {
            justify-content: flex-end;
        }
    }
}
.product-status-dropdown {
    .status-dropdown {
        width: 100px;
        border-radius: 5px !important;
        outline: none;
        text-align-last: center;
        padding: 5px 6px;
        font-size: 14px !important;
        background: #f2fff2;
        color: #606060;
        border: 0.5px solid #41b13d;

        &.danger {
            background: #fff0f0;
            border: 0.5px solid #ffbcbc;
        }

        option {
            background-color: #fff;
            font-size: 14px;
        }
    }

    .delisted-dropdown {
        width: 100px;
        border-radius: 5px !important;
        outline: none;
        text-align-last: center;
        padding: 5px 7px;
        font-size: 13px !important;
        background: #fff0f0;
        color: #606060;
        border: 0.5px solid #ffbcbc;

        &.danger {
            background: #fff0f0;
            border: 0.5px solid #ffbcbc;
        }

        option {
            background-color: #fff;
            font-size: 14px;
        }
    }
}

.reject-products-dialog {
    text-align: center;

    .input-with-dropdown-down .txt-label {
        font-size: 14px;
        font-family: "Lato";
        margin-right: 15px;
        color: #898989;
        text-align: left;
        width: 100px;
    }

    .input-with-dropdown-down {
        line-height: 2;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        width: 480px !important;
    }

    .MuiFormControl-fullWidth {
        width: 60%;
    }

    .products-title {
        text-align: center;
        .MuiTypography-root {
            font-size: 15px;
            font-family: "Lato";
            font-weight: 600;
        }
    }
    .btn-action {
        background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 48px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 160px;
        box-shadow: 0px 3px 6px #00000052;
    }
    .outlined {
        // background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 48px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 160px;
        box-shadow: 0px 3px 6px #00000052;
    }
}
.searchfilter-ic {
    color: #7e7e7e;
    margin-right: 30px;
}

.edit-product {
    cursor: pointer;
}

.edit-price-confirmation-dialog {
    width: 600px;
    padding: 10px;

    .dialog-heading {
        font-size: 16px;
        margin: 0;
    }
    .warning-message {
        background-color: #ff000047;
        height: 40px;
        display: flex;
        margin: 10px 0;
        color: red;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
        border-radius: 10px 0px 10px 0px;
        
        p {
            font-size: 14px;
        }
    }
}