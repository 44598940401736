.app-layout {
  display: flex;
  max-height: 100vh;

  .app-sidebar {
    width: 60px;
    height: 100vh;
    transition: width 0.2s;
    transition-timing-function: linear;
    z-index: 1;
    position: relative;
    box-shadow: 2px 2px 7px 2px #9e9e9e;
    .sidemenu-item {
      .sidemenu-title {
        display: none; 
      }
      .rupee-icon{
        padding: 0 12px;
        color: #818181;
      }

    }

    &:hover {
      width: 230px;
      z-index: 1000;

      .menu-header {
        img {
          &.menu-img {
            fill: #818181;
          }
          &.menu-logo {
            display: none;
          }
          &.menu-color-logo {
            display: block;
          }
        }
      }
      .menu-list {
        .sidemenu-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // cursor: pointer;
          
          .sidemenu-title {
            display: block;
            font-size: 14px;
            text-transform: capitalize;
            padding-bottom: 5px;
            color: #818181;
            cursor: pointer;
            margin: 7px 0;

          }
        }
        .sidemenu-item:hover {
          .rupee-icon {
            color: #f25822;
          }
          .sidemenu-title {
            color: #090a0c;
          }
            background: #f5f5f5;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
          
        }
        .side-menu-item {
          .menu-link {
            .menu-ic {
              img {
              }
            }
            .menu-title {
              display: block;
            }
          }
        }
      }
      .menu-footer {
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        width: 100%;
        .admin-logout-wrapper {
          display: flex;
          align-items: center;
          a {
            display: flex;
            text-decoration: none;
          }
          .icons-white {
            color: #818181;
            padding: 0 5px;
            cursor: pointer;
          }
          .logout-btn {
            display: block;
            color: #818181;
            border: none;
            background: transparent;
            cursor: pointer;
          }
        }
      }
    }
    .menu-header {
      position: relative;
      padding: 10px;

      img {
        height: auto;
        width: 100%;
        object-fit: contain;
        height: 35px;

        &.menu-color-logo {
          display: none;
        }
      }
    }
    .menu-list {
      position: relative;
      .admin-seller-listmenu {
        .sidemenu-item {
          padding-left: 15px;
        }
        .side-menu-item {
          position: relative;
          padding-left: 15px;
          margin-bottom: 5px;
          &.cursor-notAllow {
            cursor: not-allowed;
          }
        }
        .disabled-link {
          pointer-events: none;
        }
      }
      .side-menu-item {
        position: relative;
        margin-bottom: 5px;

        .menu-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #818181;

          &.active {
            background: #f5f5f5;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            color: #090a0c;

            .menu-ic {
              padding: 10px 15px;

              img {
                &.menu-img {
                  display: none;
                  color: #818181;
                }
                &.menu-color-img {
                  display: block;
                }
              }
            }
          }

          &:hover {
            background: #f5f5f5;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            color: #090a0c;

            .menu-ic {
              img {
                &.menu-img {
                  display: none;
                  color: #818181;
                }
                &.menu-color-img {
                  display: block;
                }
              }
            }
          }

          .menu-ic {
            padding: 10px 15px;

            img {
              width: 100%;
              &.menu-color-img {
                display: none;
              }
            }
          }
          .menu-title {
            display: none;
            font-size: 14px;
            text-transform: capitalize;
            padding-bottom: 5px;
          }
        }
      }
    }
    .menu-footer {
      position: absolute;
      bottom: 20px;
      .admin-logout-wrapper {
        .icons-white {
          color: #818181;
          padding: 0 15px;
          cursor: pointer;
        
        }
        .logout-btn {
          display: none;
        }
      }
      .menu-footer-ic {
        padding: 10px;
        display: block;
        cursor: pointer;
        margin: 0 auto;
      }
    }
  }

  .app-main {
    width: calc(100vw - 60px);
    // height: calc(100vh - 60px);
    background-color: #fbfbfb;
    position: relative;
    overflow: scroll;

    .app-headers {
      height: 60px;
      background-color: #fff7f0;
      display: flex;
      align-items: center;
      position: relative;
      .header-logout-wrapper {
        right: 15px;
        position: absolute;
        a {
          color: #e48412;
        }
      }
    }

    .app-container {
      background: #fbfbfb;
      width: 100%;
      // display: flex;
      justify-content: space-between;
      height: calc(100vh - 60px);
      //  padding: 20px;
    }
  }
}

.notification-menu {
  .notification-items {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    .empty-status {
      text-align: center;
    }
  }
  
  .notification-items::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.notification-items::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.notification-items::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #1ea7f2;
}

  .header-and-actions {
    display: flex;
    align-items: center;
    width: 100%;
    .notification-header {
      width: 50%;
      h1{
        font-size: 16px;
        margin: 0 15px;
        letter-spacing: 0.1px;
      }
    }

    .action{ 
      width: 50%;
      margin: 0 10px;
       p {
         width: 100%;
         text-align: end;
         width: 100%;
         text-align: end;
         color: #1ea7f2;
         font-size: 14px;
         font-weight: 600;
         cursor: pointer;
       }
    }
  }

  .view-all-notifications {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
      color: #37b1f4;
      cursor: pointer;
    }
  }
}

.sidemenu-item {
  
}