.addinventory-products{
    padding: 0 20px;
    .addproduct-btn-wrapper {
        background: #d9d9d9;
        color: #000000;
        border: 0;
        width: 100px;
        font-family: 'Lato';
        height: 30px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        margin: 15px 0;
    }
    th {
        padding: 20px 10px;
        color: #090a0c;
        font-size: 13px;
        font-weight: 800;
        top: 0px;
        z-index: 1;
        text-align: center;
        font-family: "Lato";
        border: 0;
        background-color: transparent;
    }
    td {
        border-right: none;
        border-left: none;
    }
}