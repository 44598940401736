.cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
    margin: 30px;
}

.dashboard-sub-heading {
    margin: 15px 30px;
    font-weight: 700;
    font-size: 16px;
}