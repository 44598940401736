.login-menu .MuiMenu-paper {
    top: 50px !important;
    right: 16px !important;
    left: auto !important;
}
.landing-header {
    height: 50px;
    background: #fff7f0;
    display: flex;
    .rignt-seller-content {
        width: 100%;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px 0 0;
        p{
            margin: 0;
        }

        .Account-ic-header {
            color: #B8B8B8;
            padding: 0 5px;
            cursor: pointer;
            font-size: 38px;
        }
    }
}
.header-card-wrapper {
    width: 170px;
    height: 121px;
    text-align: center;
    background: #89898912;
    position: absolute;
    right: 12px;
    top: 50px;
    box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px,
        rgb(34 34 34 / 30%) 0px 1px 18px 0px;
    .header-card-content {
        padding: 10px;
        p {
            cursor: pointer;
        }
    }
}
.landing-page-content {
    margin: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;


    .landing-card-top-wrapper {
        display: flex;
        margin-top: 3%;
    }
    
}
.poorvikaheader-logo{
    width: 120px;
    height: 50px;
    margin-left: 20px;
}
.menu-item-wrapper{
    position: absolute;
    right: 0;
}