.payments-page {
    .payments-table-layout {
        padding: 30px;
        .payments-tables-header-wrapper {
            box-shadow: 0px 1px 5px #00000029;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .payments-tables-header {
            height: 40px;
            padding: 3px 0;
            
            display: flex;
            align-items: center;

            .table-option-type{
                color: #818181;
                font-size: 14px;
                margin: 0 5px;
                text-decoration: none;
                padding: 8px 16px;
                cursor: pointer;
                p {
                    margin: 0;
                }
            }
            .active{
                color: #090a0c;
                border-bottom: 2px solid #ff6522;
                background: #fff2ed;
                border-radius: 10px 10px 0px 0px;
                font-weight: bolder;
            }
        }
    }
}

.export-excel {
    cursor: pointer;
    height: 32px;
    padding: 5px 10px;
    background: rgba(89,179,36,.4);
    color: green;
    border: transparent;
    border-radius: 5px;
    margin: 0 10px;
}