.po-table {
  background: #f5f5f5;
  height: 100%;
  padding: 15px 30px;
  background: #f5f5f5;
    height: calc(100vh - 150px) !important;
    padding: 15px  30px;
    overflow: scroll;
}
.form-wrapper-add {
  width: 100%;
  // text-align: center;
  margin: 15px 0;
  // display: flex;
  
  .name-wrapper1 {
    // width: fit-content;
    // margin: auto;
    margin: 0 30px;
  }
  .layout-flex {
    display: flex;
    .input-field-layout1 {
      width: 100%;
    }
  }

  .save-cancel-btn {
    margin: 10px;
    text-align: center;
  }

  .map-col-wrapper {
    width: 100%;
  }
}

.switch-color {
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #ff632b;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #ff632b;
  }
}

.toogle-add {
  display: flex;
  align-items: center;
}
.toogle-hide {
  display: none;
}

.country-label {
  width: fit-content;
  color: #898989;
  margin: 10px 0 0;
  font-size: 14px;
  font-family: 'Rubik';
}

.country-select {
  width: 38%;
}

.text-header1 {
  color: #ff632b;
  font-size: larger;
  font-family: 'Rubik';
  font-weight: 500;
  display: flex;
  margin: 10px;
}
.return-order {
  margin: 10px 0;
  line-height: 2;
  color: gray;
}
.multiselect-hide {
  display: none;
}

.multi-select-dropdown {
  .chip {
    background-color: #ff632b;
  }
}

.active-days-selection-wrapper {
  margin: 10px 0 0 0;
  .heading-working-days {
    font-size: 14px;
    font-family: 'Rubik';
    margin-right: 15px;
    color: #898989;
    padding: 10px 0;
  }

  .custom-days-wrapper {
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  .pop-po-main-button {
    display: flex;
    justify-content: space-between;
  }
  .purchase-order-button {
    display: flex !important;
    margin: 14px !important;
    justify-content: space-around !important;
  }
  .text-header1 .text-header2 {
    background: #F5F5F5 ;
    margin: 0;
    font-size: 14px;
    color: #000 !important;
    padding: 10px 10px;
    box-shadow: 0px 0px 3px #a5a5a5;
}
.app-layout .purchase_drop .listed-product-layout {
  padding: 0 !important;
  background: #fff !important;
}

}
.MuiGrid-spacing-xs-2 > .MuiGrid-item:nth-child(1){
  padding: 8px 30px;
}
.table-head{
  color: #3b3b3b;
  font-size: 18px;
}

.box-button {
  cursor: pointer;
}

.attach-invoice-form {
  .input-label-input-field {
    width: 100% !important;
  }
}

.half-width {
  width: 50%;
  display: flex;
  button {
    height: 40px;
  }
}

.float-left { 
  justify-content: flex-start;
}

.float-right {
  justify-content: flex-end;
}

.date-range-picker{
  height: 40px;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
    width: 100%;
}