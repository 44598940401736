.price-history-view{
    p {
        margin: 5px 0;
        font-size: 14px;
    }
    .price-update-history {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    border-bottom: 1px solid;
    border-color: #9e9e9e8a;
    p {
        padding: 3px 0;
        font-size: 14px;
    }

    .current-price {
        padding: 3px 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    }

    .increment {
        color: green;
    }

    .decrement {
        color: red;
    }

    
    
}



}

.price-history-view {
    th {
        padding: 10px !important;
        color: #090a0c !important;
        font-size: 13px !important;
        font-weight: 600 !important;
        border: 1px solid #000 !important;
        background-color: #dfe1e5 !important;
    }
    
}