.delete-contact-dialog {
    width: 500px;
    text-align: center;
    h1, 
    p {
        font-size: 16px;
    }

    .btn-wrapper {
        margin: 10px 0;
    }
}