.Addlocations-page {
    .location-layout-wrapper {
        padding: 0 30px;
        background: #fbfbfb;
        display: flex;
        height: calc(100vh - 100px);
        overflow: auto;
        .location-core-details {
            width: 350px;
            .legal-details-content {
                background: #ffff;
                box-shadow: 0px 1px 5px #00000029;
                padding-bottom: 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0 8px 20px;
               
                .registed-address-content {
                    padding: 5px 15px;
                    line-height: 1.3;
                    .address-field {
                        border: 1px solid #e8e9ec;
                        height: 113px;
                        position: relative;
                        margin-top: 5px;
                        border-radius: 5px;
                        padding: 10px;
                        overflow: scroll;
                        p {
                            margin: 0;
                            color: #b8b8b8;
                            font-size: 14px;
                            padding-right: 40px;
                        }
                        .verified-icon {
                            position: absolute;
                            color: #b8b8b8;
                            width: 15px;
                            right: 20px;
                            top: 14px;
                            cursor: pointer;
                        }
                    }
                    label {
                        font-size: 14px;
                        color: #818181;
                    }
                }
                .input-with-dropdown-down {
                    line-height: 2;

                    padding: 15px;
                    .txt-label {
                        font-size: 14px;
                        font-family: "Lato";
                        color: #898989;
                        text-align: left;
                    }
                    .input-and-error {
                        width: 100%;
                    }
                    .input-field-box {
                        padding: 6px;
                        border-radius: 5px;
                        font-family: "Lato";
                        width: 86%;
                        height: 25px;
                        border: 1px solid #069800;
                    }
                    .input-field-box-error {
                        padding: 6px;
                        border-radius: 2px;
                        border: 1px solid #e8e9ec;

                        font-family: "Lato";
                        width: 90%;
                        height: 25px;
                    }
                }
            }
        }
        .warehouse-contacts-wrapper {
            width: 350px;
            padding-left: 20px;
            .legal-details-content {
                background: #ffff;
                box-shadow: 0px 1px 5px #00000029;
                padding-bottom: 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0 8px 20px;
                margin-bottom: 25px;
                .contacts-label {
                    color: #100f0f;
                    font-size: 14px;
                    margin: 0;
                    padding-top: 10px;
                    font-weight: 500;
                }
            }
        }
        .addwarehouse-submit-btn {
            text-align: center;
            margin: 20px 0;
            .btn-action {
                background: #f25822;
                font-family: "Lato";
                font-size: 17px;
                outline: transparent;
                border: none;
                height: 48px;
                padding: 0px 15px;
                color: #fff;
                border-radius: 3px;
                cursor: pointer;
                font-weight: 600;
                width: 160px;
                box-shadow: 0px 3px 6px #00000052;
                &:hover {
                    background: #fff2ed;
                    color: #f25822;
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
        .error-text {
            text-align: left;
        }
    }
}


.locationDetails {
    .radio-btn-wrapper {
        width: 100%;
        .radio-group {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }
    .location-type {
        padding: 10px;
        margin: 0;
        font-size: 14px;
        color: #818181;
        display: flex;
        align-items: center;
        font-size: 14px;
    }
}

.address-and-operating-hours {
    width: 350px;
    padding-left: 20px;

    .address-content-container {
        .state-wrapper {
            .input-with-dropdown-down {
                line-height: 2;
                padding: 5px 15px;
                width: auto;
                .input-and-error {
                    width: auto;
                }
                .input-field-box {
                    border: 1px solid #069800;
                    padding: 5px;
                    font-family: "Lato";
                    width: 100%;
                    height: 36px;
                    border-radius: 4px;
                    box-sizing: border-box;
                }
            }
        }
        .city-state-wrapper {
            align-items: center;
            .bussiness-address-content {
                color: #f16800;
                font-size: 14px;
                font-weight: bold;
            }
            .input-with-dropdown-down {
                line-height: 1.7;
                width: 100%;
                padding: 15px;

                .input-field-box {
                    padding: 6px;
                    border-radius: 4px;
                    border: 1px solid #8d8d8da8;
                    font-family: "Lato";
                    width: 100%;
                    height: 23px;
                }
                .txt-label {
                    font-size: 14px;
                    font-family: "Lato";
                    color: #818181;
                    width: 100%;
                    text-align: left;
                }
            }
        }
        .working-days-content {
            padding: 5px 15px;
            line-height: 2;
            .text-label-content {
                right: 10px;
                position: absolute;
                &:focus {
                    background-color: #fff7f0;
                    border: 1px solid #ffddbc;
                    outline: none;
                    .hint-text {
                        display: none;
                    }
                }
            }
            .input-with-verified-icon {
                position: relative;
                display: flex;
                padding: 5px;
                border-radius: 2px;
                border: 1px solid #e8e9ec;
                font-family: "Lato";
                width: 100%;
                height: 90px;
                border-radius: 4px;
                box-sizing: border-box;
                .days-wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    align-items: center;
                    font-size: 14px;
                    color: #818181;
                    p {
                        margin: 0;
                    }
                }
            }
            .verified-icon {
                color: #b8b8b8;
                right: 0.65vw;
                position: absolute;
                width: 14px;
                top: 12px;
                cursor: pointer;
            }
        }
        background: #ffff;
        box-shadow: 0px 1px 5px #00000029;
        padding-bottom: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 8px 20px;
        margin-bottom: 25px;
    }
}