.select-drop-down-with-label {
    display: flex;
        .txt-label {
            font-size: 14px;
            font-family: 'Lato';
            margin-right: 15px;
            color: #898989;
            width: 60%;
            text-align: left;
          }
    .input-selector {
        width: 100%;
        padding: 5px;
        font-family: 'Lato';
        border: 1px solid #8d8d8da8;
        border-radius: 4px;
        outline: transparent;
    }
}