.print-form-wrapper {
    display: none;
}
.print-page-content {
    height: 100px;
}
.print-sub-heading {
    text-align: center;
    color: #ff8e33;
    font-weight: bold;
}
.activate-suppilerID-dialog {
    padding: 40px;
    text-align: center;
    .dialog-close-ic{
        display: flex;
        justify-content: flex-end;
        margin: 10px 10px 0;
    }
    .details-check-message{
        margin: 40px 5px;
        color: red;
        font-size: 16px;
        font-weight: 500;
    }
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        width: 480px;
    }
    .input-field-wrap {
        width: 200px;
        margin: auto;
    }
    .MuiDialogActions-root {
        flex: 0 0 auto;
        display: flex;
        padding: 8px;
        align-items: center;
        justify-content: center;
    }
}
