.aggrement-wrapper{
    display: flex;
}
.text-doc-wrapper{
    width: 70% !important;
}

.image-viewer {
    width: 400px;
    height:200px;

    .image-wrapper {
        border: 1px solid #000;
        margin: 0 20px;
        text-align: center;
        padding: 20px;
    }
}