.mapped-locations-dialog {
    padding: 20px;

    .locations-details-header-wrapper {
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: space-between;
        p {
            font-size: 14px;
            font-family: 'Lato';
            color: #090a0c;
            font-weight: bold;
        }
        .warehouse-selection-number {
            color: #41b13d;
        }
    }
    .round {
        position: relative;

        margin-bottom: 20px;
        .warehouse-name-code-wrapper {
            display: flex;
            align-items: center;
        }
        .days-content-wrapper {
            margin: 0;
            width: 100%;
            color: #000000;
            font-size: 14px;
            font-weight: 600;
        }

        .checkbox-label {
            background-color: #fff;
            border: 1px solid #090a0c;
            border-radius: 50%;
            cursor: pointer;
            height: 9px;
            left: 0;
            top: 0;
            width: 10px;
            margin: 10px;
        }
        .warehouse-address-wrapper {
            margin: 0;
            margin-left: 9%;
            font-size: 12px;
            color: #818181;
        }
    }

    .round input[type="checkbox"] {
        visibility: hidden;
    }

    .round input[type="checkbox"]:checked + label {
        background-color: #fb6c00;
        border-color: #090a0c;
    }

    .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }

    .workingdays-form-btn-wrapper {
        bottom: 10%;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .btn-action {
            background: #f25822;
            font-family: 'Lato';
            font-size: 17px;
            outline: transparent;
            border: none;
            height: 48px;
            padding: 0px 15px;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
            font-weight: 600;
            width: 160px;
            box-shadow: 0px 3px 6px #00000052;
            &:hover {
                background: #fff2ed;
                color: #f25822;
                font-size: 15px;
                font-weight: 600;
            }
        }
        .outlined {
            background: #fff;
            font-family: 'Lato';
            outline: transparent;
            border: 0;
            height: 30px;
            padding: 0px 15px;
            color: #090a0c;
            font-size: 18px;
            border-radius: 3px;
            cursor: pointer;
            font-weight: 500;
        }
    }
}
