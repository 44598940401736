.personal-information-form-page {
 
  .file-uploader {
    display: none;
  }

  .personal-information-form {
    width: 500px;
    margin: 0 auto;

    .image-and-name-wrapper {
      display: flex;
      .profile-image {
        width: 100px;
        height: 100px;
        border: 2px solid #898989;
        border-radius: 50px;
        cursor: pointer;
        margin-bottom: 0;
      }
      .name-wrapper {
        margin-left: 50px;
        width: 100%;
        
        
       
        // .MuiFormControl-root {
        //   width: 50%;
        // }
        // .MuiFormControl-root:first-child {
        //   width: 50%;
        //   margin-right: 15px !important;
        // }
      }
    
    }


.select-drop-down-with-label .txt-label {
    font-size: 14px;
    font-family: "Rubik";
    margin-right: 9px;
    color: #898989;
    width: 50%;
    text-align: left;
}

.input-selector {
  width: 100%;
  padding: 5px;
  font-family: "Rubik";
  border: 1px solid #8d8d8da8;
  border-radius: 2px;
  outline: transparent;
}


.select-drop-down-with-label {
  display: flex;
  width: 97%;
  margin-left: 3%;
}


    

    .edit-account-code {
      display: flex;
      .MuiFormControl-root {
        width: 85%;
      }
      .Mui-disabled {
        cursor: not-allowed;
        background-color: #dfdcdc;
      }
      .MuiFormHelperText-root {
        background-color: #fff;
      }
      .btn-action {
        height: 40px;
        margin: 20px !important;
        width: 15%;
      }
    }

    .btn-action,
    .outlined{
      height: 40px;
      margin: 15px 154px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* .profile-image-letter {
        height: 100px;
        width: 100px;
        border-radius: 100px;
        background: #ff632b;
        display: flex;
        justify-content: center;
        align-items: center; 
        h1 {
            color: #FFF;
        }
    }*/
}

.form-subtitle {
  font-size: 16px;
  border-bottom: 2px solid #ff632b;
  /* margin: 0; */
  padding: 10px 0;
  margin: 10px 0;
  width: auto;
  text-align: left;
  font-weight: 500;
}

// td {
//   padding: 0px;
// }

.personal-information-form-page .personal-information-form .input-selector {
  width: 100% !important;
  padding: 5px;
  font-family: "Rubik";
  border: 1px solid #8d8d8da8;
  border-radius: 2px;
  outline: transparent;
}

.personal-information-form .input-selector {
  width: 97% !important;
  padding: 9px 1px !important;
  font-family: "Rubik";
  border: 1px solid #8d8d8da8;
  border-radius: 4px !important ;
  outline: transparent;
  margin: 30px 15px 4px -217px !important;
}

.bank-account-page .legal-layout-wrapper {
  width: 447px !important;
  // padding: 26px 184px !important;
  // padding: 47px 2px !important;
}

// .bank-account-page .legal-layout-wrapper {
//   width: 447px !important;
// }

.input-label-input-field{
  width: 95%;
  margin: 0px 0px 0px 8px;
}

.input-label-input-field .text-label {
  margin: 0;
  font-size: 16px;
  color: #818181;
}

.bank-account-page .legal-layout-wrapper .legal-details-Header {
  height: 38px;
  width: 96%;
  margin: 0 0 0 -2px !important;
  box-shadow: 0px 1px 1px #00000029;
  background: #0000000d;
  display: flex;
  padding: 11px;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px 11px;
  justify-content: space-between;
}

.personal-information-form-page .personal-information-form .select-drop-down-with-label {
  display: flex;
  width: 99%;
  margin-left: 1%;
}

.personal-information-form-page .personal-information-form .select-drop-down-with-label .txt-label {
  font-size: 16px;
  font-family: "Rubik";
  /* margin-right: 7px; */
  color: #898989;
  width: 50%;
  text-align: left;
  margin: 6px 3px 2px 5px;
}