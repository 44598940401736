.listed-product-layout {
    .product-col:hover{
        cursor: pointer;
        text-decoration: underline;

    }
    .delisted-dropdown:hover {
        cursor: pointer;

    }
    .delisted-dropdown:disabled {
        cursor:not-allowed

    }
   
    padding: 30px;
    background: #fbfbfb;
    // height: calc(100vh - 100px);
    // overflow: auto;
    th {
        padding: 20px 10px;
        color: #090a0c;
        font-size: 13px;
        font-weight: 800;
        top: 0px;
        z-index: 1;
        text-align: center;
        font-family: "Lato";
        border: 0;
        background-color: transparent;
    }

    .product-header-btn {
        display: flex;
            justify-content: space-between;
            align-items: center;
        button.addproduct-btn-wrapper {
            background: #59b32466;
            color: green;
            border: 0;
            width: 200px;
            height: 30px;
            border-radius: 5px;
            font-weight: 600;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
        }

    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        background: #d9d9d9;
        font-weight: 600;
        color: #000000;
    }




        .more-action-btn {
            border: 0.5px solid #d9d9d9;
            position: absolute;
            right: 10px;
            display: flex;
            align-items: center;
            width: 140px;
            justify-content: space-around;
        }
        .more-action-hide {
            display: none;
        }
        .more-action-ic {
            transform: rotate(180deg);
        }
        .more-action-dropdown {
            position: absolute;
            right: 10px;
            z-index: 9;
            top: 30px;
            background: #fff7f0;
            width: 105px;
            padding-bottom: 25px;
            padding: 0 15px 25px;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #f5f5f5;
            border-radius: 0px 0px 4px 4px;
        }
        p {
            font-size: 12px;
            color: #090a0c;
            font-weight: bold;
        }
    }
    .Inventory-table-layout {
        .Inventory-table-header {
            display: flex;
            align-items: center;
            justify-content: left;
            height: 40px;
            border: 1px solid #f5f5f5;
            background-color: #ffffff;
            box-shadow: 0px 1px 5px #00000029;
            margin: 30px 0 0;
            padding: 5px 0;
            .products-selector {
                color: #090a0c;
                font-size: 14px;
                margin: 0 5px;
                text-decoration: none;
                p {
                    padding: 8px 22px;
                    color: #818181;
                    font-size: 14px;
                }
                &.active {
                    p {
                        color: #090a0c;
                        border-bottom: 2px solid #ff6522;
                        background: #fff2ed;
                        border-radius: 10px 10px 0px 0px;
                        font-weight: bolder;
                    }
                }
            }
            .listed-products-selector {
                display: flex;
                align-items: center;
                span {
                    color: #8181818a;
                }
                &:nth-child(5) span {
                    display: none;
                }
            }
            // .inventory-products-search {
            //     ::-webkit-input-placeholder {
            //         /* Chrome/Opera/Safari */
            //         font-family: 'Lato';
            //         color: #b8b8b8;
            //         font-size: 13px;
            //     }
            //     ::-moz-placeholder {
            //         /* Firefox 19+ */
            //         font-family: 'Lato';
            //     }
            //     .products-search {
            //         width: 348px;
            //         height: 25px;
            //         border: 1px solid #b8b8b8;
            //     }
            // }
            .search-input-field {
                margin: 0 auto;
                visibility: hidden;
                display: flex;
                align-items: center;
                width: 240px;
                height: 28px;
                border: 1px solid #d0c9c9;
                background: #fff;
                border-radius: 3px;
                justify-content: space-around;
                .search-ic {
                    color: #7e7e7e;
                    width: 18px;
                }
                input {
                    border: 0;
                    width: 85%;
                    outline: 0;
                }
                ::placeholder {
                    color: #b8b8b8;
                    font-family: "Lato";
                    font-size: 11px;
                }
            }
            .products-filters {
                display: flex;
                align-items: center;
                margin: 0 20px;
                // width: 25%;
                justify-content: space-between;
                .status-dropdown {
                    border: 0.5px solid #f5f5f5;
                    background: #fafafa;
                    padding: 5px;
                    margin: 0 15px;
                    color: #818181;
                    font-size: 14px;
                    font-family: "Lato";
                    outline: none;
                }
                .sort-btn {
                    display: flex;
                    align-items: center;
                    background: #fafafa;
                    border: 0.5px solid #f5f5f5;
                    color: #818181;
                    padding: 5px;
                    width: 60px;
                    justify-content: center;
                    .sort-ic {
                        width: 15px;
                        color: #818181;
                    }
                }
                p {
                    color: #090a0c;
                    font-family: "Lato";
                    font-size: 14px;
                    font-weight: 800;
                    padding: 5px 8px;
                }
            }
        }
        .Inventory-table-body {
            width: 100%;

            .inventory-products-table {
                th,
                td {
                    border-right: none;
                    border-left: none;
                }
            }
        }
    }
}

.bread-crumb-layout {
    .MuiTypography-root .MuiBreadcrumbs-root .mt-2 .MuiTypography-body1 .MuiTypography-colorTextSecondary {
        margin: 10px 30px;
        a {
            text-decoration: none;
        }
    }
    .MuiBreadcrumbs-ol {
        margin: 10px 30px;
    }
    .bread-crumb-navlink {
        padding: 0 15px;
        height: 30px;
        box-shadow: 0px 1px 5px #00000029;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border-radius: 5px;
        font-weight: 800;
        border: 1px solid #b8b8c8b5;
    }
}

.dropbtn {
    display: inline-flex;
    padding: 4px 10px;
    background: #f2fff2;
    border: 0.5px solid #41b13d;
    border-radius: 100px;
    color: #606060;
    font-size: 11px;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #3c9e38;
    color: #fff;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: auto;
    min-width: 100px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    margin: 0;
}

.dropdown p:hover {
    background-color: #ddd;
}

.show {
    display: block;
}
.searchfilter-icons {
    color: #7e7e7e;
    margin-right: 30px;
    visibility: hidden;
}
.search-sort-dialog {
    padding: 10px;
}


.search-filter-products-dialog {
    text-align: center;
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        width: 480px !important;
        position: absolute;
        bottom: auto
    }

    .MuiFormControl-fullWidth {
        width: 60%;
    }
    .MuiDialog-scrollPaper {
        justify-content: flex-end;
        margin-top: 60px;
    }

    .MuiDialog-paperWidthSm {
        max-width: 100%;
    }

    .MuiDialog-paperScrollPaper {
        display: block;
        max-height: 100%;
        height: auto;
        margin: 0;
        width: 40vw;
    }
    .MuiBackdrop-root {
        background: transparent;
    }
    .MuiDialogActions-root.MuiDialogActions-spacing {
        justify-content: center;
    }
    .products-title {
        text-align: center;
        .MuiTypography-root {
            font-size: 15px;
            font-family: "Lato";
            font-weight: 600;
        }
    }
    .btn-action {
        background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 35px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 100px;
        box-shadow: 0px 3px 6px #00000052;
    }
    .outlined {
        // background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 35px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 100px;
        box-shadow: 0px 3px 6px #00000052;
    }
}
