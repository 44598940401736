.drag-and-drop-wrapper {
    width: 100%;
    border: 1px dotted;
    margin: 10px;
    background: #93939387;
    cursor: pointer;

    .drag-n-drop-text {
        font-family: 'Lato';
    font-size: 14px;
    }
}
.drag-and-drop-wrapper-profile{
    
    border: 1px dotted;
    
    background: #93939387;
    cursor: pointer;
    .drag-n-drop-text {
        font-family: 'Lato';
    font-size: 14px;
    }
}