.seller-pending-activation-page {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  .pending-card {
    width: 600px;
    height: 275px;
    box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px, rgb(34 34 34 / 30%) 0px 1px 18px 0px;
    border-radius: 5px;
  }
  .verification-image {
      width: 150px;
  }

  .heading {
    font-size: 20px;
    font-family: 'Lato';
  }

  .pending-content {
    padding: 0 30px;
    font-family: 'Lato';
    font-size: 16px;
    color: #8b8888;
  }


}
