.roles-and-permission-page {
    padding: 0;
    display: flex;
    width: 100%;
    position: relative;
    .left-wrapper {
        width: 320px;
        margin: 0;
        padding: 25px 20px;
        height: 100%;
        .bgactive {
            background-color: #f25822;
            color: #fff;
            border-radius: 7px;
        }
        .roles-list-wrapper {
            padding: 0 10px;
            justify-content: space-between;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: 1px solid #e4e4e4;
            .roles-name {
                font-size: 15px;
                font-weight: 400;
            }
        }
        .save-btn-wrap {
            text-align: center;
            margin: 20px 0;
             .btn-action {
                margin: 10px 0px;
                width: 150px;
            }
        }
    }
    .right-wrapper {
        width: calc(100% - 250px);
        padding: 25px 20px;
        height: calc(100vh - 0px);
        overflow: auto;
        box-sizing: border-box;
        border-left: 1px solid #e4e4e4;
        .right-content {
            max-width: 80%;
            .add-role-header {
                text-align: center;
                font-size: 18px;
                margin: 0;
                font-weight: 700;
            }
        }
        .save-btn-wrap {
            text-align: right;
            .btn-action {
                margin: 10px 0px;
            }

            .txt-label {
                span {
                    display: none;
                }
            }
        }

        .name-role-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .input-with-labels-down {
                width: auto;
            }
        }
        .permisssion-txt-wrap {
            font-size: 19px;
            font-weight: 600;
        }
        .roles-content-wrap {
            .roles-header {
                font-size: 16px;
                font-weight: 600;
                border-bottom: 2px solid #ff8e33;
                padding-bottom: 10px;
            }
            .roles-select-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 30px;
                border-radius: 5px;
                padding: 0 5px;
                &:hover {
                    background-color: #e4e4e4;
                }
                p {
                    margin: 0;
                    font-size: 14px;
                }
            }
        }
    }
    .pending-activation-page {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;
        width: calc(100% - 340px);
        .pending-card {
            width: 600px;
            height: 275px;
            box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px,
                rgb(34 34 34 / 30%) 0px 1px 18px 0px;
            border-radius: 5px;
        }
        .verification-image {
            width: 150px;
        }

        .heading {
            font-size: 20px;
            font-family: "Lato";
        }

        .pending-content {
            padding: 0 30px;
            font-family: "Lato";
            font-size: 16px;
            color: #8b8888;
        }
    }
}
.editRole-custom-checkbox {
    .roles-select-wrap {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .roles-select-wrap input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 20px;
        width: 20px;
        right: 0px;
        z-index: 10;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        height: 15px;
        width: 15px;
        border-radius: 2px;
        border: 2px solid rgba(0, 0, 0, 0.54);
        margin: 3px;
    }

    /* On mouse-over, add a grey background color */
    .roles-select-wrap:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .roles-select-wrap input:checked ~ .checkmark {
        background-color: #ff8e33;
        width: 18px;
        height: 18px;
        border: 0;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .roles-select-wrap input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .roles-select-wrap .checkmark:after {
        left: 7px;
        top: 2px;
        width: 3px;
        height: 9px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
