.notification-item {
  height: 60px;
  display: flex;
  background-color: #03a9f433;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #03a9f433;
  cursor: pointer;
  .icon-wrapper {

  
  .notification-icon-wrapper {
    background-color: #1ea7f2;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .notification-icon {
      color: #fff;
    }
  }
}
  .notification-title-and-description {
    margin: 0 10px;
    p{
      margin: 1px 0;
    }
    .notification-title {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.1px;
    }
    .notification-description {
      font-size: 12px;
      color: #8f8d8dd9;
      letter-spacing: 0.1px;
    }
  }
  .notification-date-wrapper {
    width: 35%;
    .notification-date {
      text-align: end;
      font-size: 12px;
      color: #8f8d8dd9;
    }
  }
}


.read-notification-item {
  background-color: #FFF !important;

  .notification-icon-wrapper {
    background-color: #b8b8b8 !important
  }
}