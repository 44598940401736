.input-label-input-field {
    padding: 15px;
    line-height: 2;
    .text-label {
        margin: 0;
        font-size: 14px;
        color: #818181;
    }
    .info-text{
        margin: 0;
        font-size: 12px;
        color: #818181;
    }
    .text-label-content-disabled {
        border: 1px solid #eeeeee !important;
        cursor: not-allowed;
        background: #eeeeee !important;
        color: #000;
    }
    .text-label-content {
        padding: 5px;
       border: none;
        font-family: "Lato";
        width: 100%;
        height: 36px;
        border-radius: 4px;
        box-sizing: border-box;
        color: #818181;
        &:focus {
            background-color: #fff7f0;
            border: 1px solid #ffddbc;
            outline: none;
            .hint-text {
                display: none;
            }
        }
    }
    .text-area-content {
        resize: none;
        color: #818181;
        padding: 5px;
        border-radius: 2px;
        border: 1px solid #e8e9ec;
        font-family: "Lato";
        width: 100%;
        height: 36px;
        border-radius: 4px;
        box-sizing: border-box;
        height: 126px;
        outline: none;
    }
    .error-text {
        width: 100%;
        margin: 0;

        font-size: 11px;
        text-align: right;
        color: red;
        font-family: "Lato";
    }
    .hint-text {
        width: 100%;
        margin: 0;
        font-size: 11px;
        text-align: right;
        color: #069800;
        font-family: "Lato";
    }
    .input-with-verified-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #069800;
        padding: 0 5px 0 0;
    }
    .verified-icon {
        color: #069800;
        right: 0.65vw;
        width: 14px;
        top: 14px;
    }
}
