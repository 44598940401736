.document-uploading-page {
  width: 800px;
  margin: 0 auto;

  .file-uploading-wrapper {
    display: flex;
    justify-content: center;
    .field-and-drag-drop {
      display: flex;
      width: 800px;
      .detail-field {
        width: 50%;
      }
      .drop-area {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .field-and-drag-drop-signature-and-agreement {
    display: flex;
    .drop-area {
      width: 50%;
      margin: 0 20px;
      .btn-action {
        width: 100% !important;
        border: 1px dotted;
        margin: 10px;
        background: #93939387;
        cursor: pointer;
        color: black;
        border-radius: 0;
        height: 46px;
      }
    }
  }
}

.btn-to-link {
  font-size: 14px;
  font-family: "Lato";
  background: transparent;
  outline: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: #fa632b;
}
.sign-content-wrap {
  display: flex;
  justify-content: center;
}
.digital-sign-img {
  max-width: 215px;
  height: 40px;
  cursor: pointer;
}
.digital-signature-dialog {
  .dialog-action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .singature-panel-hint{
    text-align: center;
    font-size: 14px;
  }

  .sigCanvas {
    touch-action: none;
    margin: 0 20px;
    border: 1px solid #00000070;
  }
}
