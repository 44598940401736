.seller-unique-code-input{
    display: flex;
    margin: 0;
    // margin: 15px 0 0 15px;
  .txt-label {
    font-size: 14px;
    font-family: 'Lato';
    margin-right: 8px;
    color: #898989;
    width: 50%;
    text-align: left;
  }

  .input-field-box:disabled {
    pointer-events: auto! important;
    cursor: not-allowed! important;
    background-color: #8d8d8d63;
  }

  .input-field-box {
    padding: 6px;
    border-radius: 2px;
    border: 1px solid #8d8d8da8;
    font-family: 'Lato';
    width: 100%;
  }

  .input-field-box-error {
    padding: 6px;
    border-radius: 2px;
    border: 1px solid red;
    font-family: 'Lato';
    width: 100%;
  }

  .input-and-error {
      margin: 0 0 0 35px;
      width: 100%;
     .input-and-button {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
     }
  }

  .input-field-box:focus,
  .input-field-box-error:focus {
    outline: none !important;
    border: 1px solid #f44336;
  }

  .input-field-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Lato';
    font-size: 12px;
  }

  .btn-get-otp {
    width: 30%;
    font-size: 12px;
    color: green;
    font-family: 'Lato';
    border: 1px solid green;
    padding: 6px;
    cursor: pointer;
  }
  .hint-text {
    width: 100%;
    margin: 0;
    padding: 3px;
    font-size: 11px;
    color: #a2a2a2;
    text-align: left;
    font-family: 'Lato';
  }
  
}

.error-text {
    width: 100%;
    margin: 0;
    padding: 3px;
    font-size: 11px;
    text-align: left;
    color: red;
    font-family: 'Lato';
  }