.autocomplete-search {
  width: 300px;
  padding: 8px 10px;

  .MuiFormControl-fullWidth {
    width: 100%;
    margin: 0;
  }

  .MuiInputBase-root {
    height: 30px;
    background-color: #fff;
    zoom: 0.9;

    input {
      color: #333;
    }
  }
  .MuiInputLabel-formControl.MuiFormLabel-filled {
    color: #fff !important;
  }
  .MuiInputLabel-formControl.Mui-focused {
    color: #fff !important;
  }
  .MuiFormLabel-root {
    font-size: 12px;
    color: #333;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 7px) scale(1);
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 5.5px 4px;
  }
}
.text-wrapper {
  font-family: "Lato";
  text-align: center;
  margin: 100px 0;
}
.seller-admin-view {
  display: flex;
  .seller-sidebar {
    background-color: #f25822;
  }
}
.search-header {
  display: flex;
  background-color: #f25822;
  top: 0px;
  position: fixed;
  left: 96px;
  width: 100%;
  z-index: 1000;
}

.inventory:hover {
  display: none;
}

.header-wrapper {
  height: 0;
  .admin-sub-menu-with-selection {
    //  height: 44px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Lato";
    cursor: pointer;

    .dd-lynk {
      padding: 10px 14px;
      &.active {
        background-color: #fff;
        color: #333;
      }
    }

    .main-heading {
      margin: 0;
      line-height: 44px;
    }

    .admin-sub-menu-line-item {
      display: none;
      padding: 0px;
      width: 120px;
      background: #eaeaea;
      box-shadow: 1px 2px 2px 1px #aaa5a5b8;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
      margin: 0;

      .sub-menu {
        color: red !important;
        line-height: 44px;
        line-height: 44px;
        width: 100%;
        padding: 0 10px;
        overflow-x: hidden;
      }

      sub-menu:hover {
        background-color: #ff632b !important;
        line-height: 44px;
        color: #fff !important;
        line-height: 44px;
        width: 100%;
        padding: 0 10px;
        overflow-x: hidden;
      }
      .hover-sub-menu-text {
        // padding: 0 10px;
        color: #f25822;
        .MuiTypography-body1 {
          margin: 10px;
        }
      }
    }
  }

  .admin-sub-menu-with-selection:hover {
    .admin-sub-menu-line-item {
      display: inline-block;
      height: 80px;
    }
  }
}

.admin-seller-portal {
  // margin-top: 40px;
}

.selected {
  background-color: #fff;
  color: #333;
}
.submenu-chips-btn {
  // margin-top: 20px;
  display: flex;

  .chipsbtn-heading {
    margin: 0;
    padding: 5px 10px;
  }
  .product-navlinks {
    text-decoration: none;
    color: #333;
    &.active {
      .menu-item-list {
        color: #fff;
        background: #f25822;
      }
    }
    .menu-item-list {
      margin: 10px;
      border: 1px solid #ff632b;
      border-radius: 100px;
    }
  }
}
.router-admin-link {
  display: flex;
  align-items: center;
  margin: 0 10px;
  border: 1px solid;
}

.seller-list-page {
  .seller-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-input {
      color: #8d8d8d;
      font-size: 13px;
      border: none;
      outline: none;
      box-shadow: rgb(63 63 63 / 15%) 0px 1px 3px 0px inset;
      border-radius: 4px;
      margin: 0px;
      padding: 10px 45px 10px 15px;
      height: 22px;
      width: 229px;
      margin-right: 30px;
      background-color: #f2f2f2;
    }
  }
  .all-products-pagination {
    margin-top: 50px;
    .MuiPagination-ul {
      justify-content: flex-end;
    }
  }

  .registered-sellers {
    padding: 0 30px;
    font-size: 20px;
    margin: 10px 0;
  }

  .active-seller-hint-text {
    padding: 0 30px;
    margin: 5px 0;
    font-size: 14px;
    color: #a4a2a2e6;
  }
  .sellers-card-wrapper {
    padding: 0 30px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    .active-Sellers-list-card {
      cursor: pointer;
      margin: 10px;
      .legal-details-content {
        background: #ffff;
        box-shadow: 0px 1px 3px #00000029;
        padding-bottom: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
