.track-product-layout {
    display: flex;
    align-items: center;
    .search-product-list {
        margin: 0 20px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 5px 5px 0px 0px;

        .track-product-header {
            margin: 0;
            padding: 12px;
            background: #f5f5f5;

            color: #090a0c;
            font-size: 14px;
            font-weight: bold;
            font-family: 'Lato';
            width: 70.21vw;
        }
        .search-product-layout {
            padding: 12px;
            .track-products-table {
                border: 0;
                thead {
                    border-bottom: 1px solid #6960603d;
                }
                th,
                td {
                    border: none;
                    background-color: transparent;
                }

                tr {
                    border-bottom: 1px solid #eee;
                }
                th {
                    color: #090a0c;
                    font-size: 14px;
                    font-weight: 500;
                }
                td {
                    height: 100px;
                }
                th:first-child {
                    width: auto;
                }
                .request-Id {
                    color: #4095dc;
                }
                .product-description-details {
                    color: #606060;
                    font-size: 11px;
                    font-family: 'Lato';
                    width: 250px;
                    line-height: 2;
                    text-align: left;
                }
                .status-approved {
                    margin: 0 auto;
                    width: fit-content;
                    padding: 3px 20px;
                    border: 0.5px solid #41b13d;
                    border-radius: 12px;
                    background: #f2fff2;
                    font-size: 11px;
                }
                .status-disapproved {
                    margin: 0 auto;
                    width: fit-content;
                    padding: 3px 20px;
                    border: 0.5px solid #ffbcbc;
                    border-radius: 12px;
                    background: #fff0f0;
                    font-size: 11px;
                }
            }
        }
    }
}
