.profile-details-page {
    .bread-crumb-layout {
        .MuiTypography-root .MuiBreadcrumbs-root .mt-2 .MuiTypography-body1 .MuiTypography-colorTextSecondary {
            margin: 10px 30px;
            a {
                text-decoration: none;
            }
        }
        .MuiBreadcrumbs-ol {
            margin: 10px 30px;
        }
        .bread-crumb-navlink {
            padding: 0 15px;
            height: 30px;
            box-shadow: 0px 1px 5px #00000029;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 5px;
            font-weight: 800;
            border: 1px solid #b8b8c8b5;
        }
    }
    .profile-detils-wrapper {
        display: flex;
    }

    .profile-details-layout {
        display: flex;
        padding: 20px;
        height: calc(100vh - 100px);
        overflow: auto;
        .pd-sidemenu {
            width: 250px;
            box-shadow: 0px 5px 5px #00000029;
            border-radius: 10px;
            height: fit-content;
            padding-bottom: 10px;
            background-color: #fff;
            .layout-Header {
                height: 35px;
                margin: 0;
                box-shadow: 0px 0.5px 1px #00000029;
                background: #0000000d;
                display: flex;
                padding: 8px;
                justify-content: space-around;
                align-items: center;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                p {
                    font-weight: 600;
                    color: #090a0c;
                    padding: 0 5px;
                }
            }
            .layout-card-content {
                height: fit-content;
                background: #ffff;
                .select-content {
                    display: flex;
                    text-decoration: none;
                    align-items: center;
                    justify-content: left;
                    padding: 0 10px;
                    height: 36px;
                    &.active {
                        .select-indicator {
                            width: 10px;
                            height: 10px;
                            border: 1px solid #090a0c;
                            border-radius: 50px;
                            margin: 10px;
                            background: #fb6c00;
                        }
                        p {
                            color: #090a0c;
                            font-weight: 600;
                        }
                    }
                    .select-indicator {
                        width: 10px;
                        height: 10px;
                        border: 1px solid #bebebe;
                        border-radius: 50px;
                        margin: 10px;
                    }
                    p {
                        color: #b8b8b8;
                    }
                }
            }
        }
        .pd-container {
            background: #fbfbfb;
            width: 100%;
            height: calc(100vh - 60px);
        }
        .prodout-details-content {
            display: flex;
        }
    }
}
// .profile-details-header{
//     width: 100vh;
// }
