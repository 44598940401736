.background {
  background-color: #ffffff;
  min-height: 100vh;
  overflow: auto;
}

p {
  font-family: "Lato";
}

/* Onboarding related classes */
.error-text {
  text-align: left;
  font-family: "Lato";
  color: red;
  font-size: 11px;
  // font-weight: 400;
  padding: 5px 0;
}

.form-subtitle {
  font-size: 16px;
  border-bottom: 2px solid #ff632b;
  /* margin: 0; */
  padding: 10px 0;
  margin: 10px 0;
  width: auto;
  text-align: left;
  font-weight: 500;
}

input:focus,
button:focus {
  outline: none;
}
.MuiTypography-body1 {
  font-family: "Lato" !important;
}
.sort-icon{
  font-size: 1rem;
  padding: 0px 5px;
  cursor: pointer;
}
.filter-head{
  font-size: 1rem;
  display: flex;
  padding: 0px;
  align-items: center;
  
  .filter-item{
    padding: 10px;
    .btn-action{
      margin: 10px 10px;
    }
  }
  .filter-icon{
    color: #ff632b;
  }
}

.admin-sidebar {
  background-color: #f1f2f4;
  min-height: 100vh;
  display: flex;
  position: relative;

  .side-navbar {
    background-color: #f25822;
    width: 100px;
    align-items: center;
    overflow: hidden;
    position: fixed;
    height: 100vh;
  }
  .admin-content {
    background: #fff;
    width: calc(100vw - 100px);
    // height: calc(100vh - 44px);
    // margin-top: 44px;
    margin-left: 100px;
    overflow: auto;
    // padding: 25px;
  }

  .sidebar-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 44px;
    // display: flex;
    align-items: center;

    .router-link {
      text-decoration: none;
      padding: 15px;
      // color:#f1f2f4;
      font-size: 15px;
      cursor: pointer;
      font-family: roboto;
      height: auto;
      align-items: center;
      display: flex;
      color: #fff;
      flex-flow: column;
      align-items: center;
    }
    .router-btn:active {
      background-color: red;
    }
  }

  .logout-wrapper {
    position: absolute;
    bottom: 0;
    padding: 30px;

    .icons-white {
      color: #fff;
      padding: 0 5px;
      cursor: pointer;
    }
  }
}
.location-inventory-page {
  .bread-crumb-layout {
      .MuiTypography-root .MuiBreadcrumbs-root .mt-2 .MuiTypography-body1 .MuiTypography-colorTextSecondary {
          margin: 10px 30px;
          a {
              text-decoration: none;
          }
      }
      .MuiBreadcrumbs-ol {
          margin: 10px 30px;
      }
      .bread-crumb-navlink {
          padding: 0 15px;
          height: 30px;
          box-shadow: 0px 1px 5px #00000029;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          border-radius: 5px;
          font-weight: 800;
          border: 1px solid #b8b8c8b5;
      }
  }
  .profile-detils-wrapper {
      display: flex;
  }

  .profile-details-layout {
      display: flex;
      padding: 20px;
      height: calc(100vh - 100px);
      overflow: auto;

      .pd-sidemenu {
          width: 250px;
          box-shadow: 0px 5px 5px #00000029;
          border-radius: 10px;
          height: fit-content;
          padding-bottom: 10px;
          background-color: #fff;
          .layout-Header {
              height: 35px;
              margin: 0;
              box-shadow: 0px 0.5px 1px #00000029;
              background: #0000000d;
              display: flex;
              padding: 8px;
              justify-content: space-around;
              align-items: center;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;

              p {
                  font-weight: 600;
                  color: #090a0c;
              }
          }
          .layout-card-content {
              padding-bottom: 25px;
              background: #ffff;
              .select-content {
                  display: flex;
                  text-decoration: none;
                  align-items: center;
                  justify-content: left;
                  // padding: 0 10px;
                  height: 36px;
                  &.active {
                      .select-indicator {
                          width: 10px;
                          height: 10px;
                          border: 1px solid #090a0c;
                          border-radius: 50px;
                          margin: 10px;
                          background: #fb6c00;
                      }
                      p {
                          color: #090a0c;
                          font-weight: 600;
                      }
                  }
                  .select-indicator {
                      width: 10px;
                      height: 10px;
                      border: 1px solid #bebebe;
                      border-radius: 50px;
                      margin: 10px;
                  }
                  p {
                      color: #b8b8b8;
                  }
              }
          }
      }
      .pd-container {
          background: #fbfbfb;
          width: 100%;
      }
      .prodout-details-content {
          display: flex;
      }
  }
}
// .profile-details-header{
//     width: 100vh;
// }

.product-table-wrapper {
  background-color: rgb(255, 255, 255);
  padding: 20px 30px 30px;
  min-height: calc(100vh - 300px);
}

table {
width: 100%;
border: 1px solid rgb(223, 225, 229);
border-collapse:collapse;
}
th {
padding: 10px;
color: rgb(99, 99, 99);
font-size: 13px;
font-weight: normal;
text-align: left;
top: 0px;
background-color: rgb(242, 242, 242);
z-index: 1;
text-align: center;
border: 1px solid rgb(223, 225, 229);
font-family: 'Lato';
}

// th:first-child {
//   width: 20px;
// }

// th:nth-child(2) {
//   width: 325px;
// }

.empty-data {
text-align: center;
padding: 10px 0;
font-family: 'Lato';
color: rgb(137, 137, 137);
}

.pagination-wrapper {
height: 40px;
border-bottom: 1px solid rgb(223, 225, 229);
border-left: 1px solid rgb(223, 225, 229);
border-right: 1px solid rgb(223, 225, 229);
display: flex;
justify-content: flex-end;
align-items: center;
}

.current-page {
margin: 0;
font-size: 12px;
font-family: 'Lato';
padding: 2px 10px;
background-color: #f2f2f2;
}

td {
padding: 10px;
  color: #636363;
  font-size: 13px;
  font-weight: normal;
  z-index: 1;
  text-align: center;
  border: 1px solid #dfe1e5;
  font-family: 'Lato';
}

tr:hover{
background-color: #f2f2f2;
}

.app-header {
height: 44px;
background-color: #ff632b;
width: 100%;
display: flex;
align-items: center;
margin-bottom: 10px;
position: fixed;
z-index: 1000;
top: 0;
h1 {
  color: #ffffff;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  padding: 0 20px;
}

img {
  zoom: 0.75;
  padding: 0 15px;
}

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  .router-link {
    text-decoration: none;
    padding: 0 15px;
    // color:#f1f2f4;
    font-size: 15px;
    cursor: pointer;
    display: inline;
    height: 44px;
    align-items: center;
    display: flex; 
    
  }
  .router-btn:active{
      background-color: red;
  }
  // li{
  //     display: inline;

  //     a{
  //         text-decoration: none;
  //         padding: 0 15px;
  //         color: #FFF;
  //         font-size: 15px;
  //         cursor: pointer;

  //     }

  // }
}

.right-hand-menu {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px 0 0;
  p {
    padding: 0;
    margin: 0;
    color: #fff;
    font-size: 14px;
  }
  .icons-white {
    color: #fff;
    padding: 0 5px;
    cursor: pointer;
  }
}
}

.parent {
background-color: #f1f2f4;
min-height: 100vh;
} 

.page-content {
padding: 50px 30px;
background: #FFF;
min-height: 80vh;
}

.inventory:hover {
display: none;
}

.sub-menu-with-selection {
height: 44px;
display: flex;
justify-content: center;
align-items: center;
padding: 0 10px !important;
color: #FFF;
font-family: 'Lato';
cursor: pointer;
display: inline;
.main-heading {
  margin: 0;
  line-height: 44px;
}

.sub-menu-line-item{
  display: none;
  padding: 0px;
  width: 120px;
  background: #eaeaea;
  box-shadow: 1px 2px 2px 1px #aaa5a5b8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  
  .sub-menu {
    color: red !important;
    line-height: 44px;
   line-height: 44px;
    width: 100%;
    padding: 0 10px; 

    &:hover {
      background: #ff632b !important;
      color: #fff !important;
    }
  }
}
}

.sub-menu-with-selection:hover {
.sub-menu-line-item{
  display: inline-block;
}
}


.product-table-wrapper {
background-color: rgb(255, 255, 255);
padding: 20px 30px 30px;
min-height: calc(100vh - 300px);
}

.products-layout {
.page-title {
  margin: 10px 0;
  font-family: 'Lato';
  font-weight: 500;
  font-size: 22px;
  color: rgb(34, 34, 34);
}

.add-products {
  text-align: end;
}
.router-link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  display: inline;
  align-items: center;
  display: flex;
}
.btn-primary {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
}
.btn-primary-edit {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 25px;
  width:1105px;
}
.search-box {
  display: inline-flex;
  .search-input {
    margin: 0;
    height: 18px;
    width: 200px;
    border: 1px solid #ff632b54;
    outline: none;
   margin-top: 5px;
    padding-left: 10px;
    border-top-right-radius:unset  ;
    border-bottom-right-radius: unset;
  }

  .search-btn {
    border-radius: 0 5px 5px 0;
    margin-right: 30px;
    height: 39px;
    width: 38px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 0;
  }

  .search-icon {
    font-size: 20px;
    padding-top: 6px;
  }

  .search-input::placeholder {
    font-size: 13px;
    font-family: 'Lato';
    font-weight: 400;
  }
}
}
table {
width: 100%;
border: 1px solid rgb(223, 225, 229);
border-collapse:collapse;
}
// th {
//   padding: 10px;
//   color: rgb(99, 99, 99);
//   font-size: 13px;
//   font-weight: normal;
//   text-align: left;
//   position: sticky;
//   top: 0px;
//   background-color: rgb(242, 242, 242);
//   z-index: 1;
//   text-align: center;
//   border: 1px solid rgb(223, 225, 229);
//   font-family: 'Lato';
// }

// th:first-child {
//   width: 20px;
// }

// th:nth-child(2) {
//   width: 325px;
// }

.empty-data {
text-align: center;
padding: 10px 0;
font-family: 'Lato';
color: rgb(137, 137, 137);
}

.pagination-wrapper {
height: 40px;
border-bottom: 1px solid rgb(223, 225, 229);
border-left: 1px solid rgb(223, 225, 229);
border-right: 1px solid rgb(223, 225, 229);
display: flex;
justify-content: flex-end;
align-items: center;
}

.current-page {
margin: 0;
font-size: 12px;
font-family: 'Lato';
padding: 2px 10px;
background-color: #f2f2f2;
}

td {
padding: 10px;
  color: #636363;
  font-size: 13px;
  font-weight: normal;
  z-index: 1;
  text-align: center;
  border: 1px solid #dfe1e5;
  font-family: 'Lato';
}

tr:hover{
background-color: #f2f2f2;
}

.product-table-wrapper {
background-color: rgb(255, 255, 255);
padding: 20px 30px 30px;
min-height: calc(100vh - 300px);
}

.products-layout {
.page-title {
  margin: 10px 0;
  font-family: 'Lato';
  font-weight: 500;
  font-size: 22px;
  color: rgb(34, 34, 34);
}

.add-products {
  text-align: end;
}
.router-link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-family: roboto;
  display: inline;
  align-items: center;
  //display: flex;
}
.btn-primary {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
}
.btn-primary-edit {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 25px;
  width:140px;
}
.search-box {
  display: inline-flex;
  .search-input {
    margin: 0;
    height: 18px;
    width: 200px;
    border: 1px solid #ff632b54;
    outline: none;
   margin-top: 5px;
    padding-left: 10px;
    border-top-right-radius:unset  ;
    border-bottom-right-radius: unset;
  }

  .search-btn {
    border-radius: 0 5px 5px 0;
    margin-right: 30px;
    height: 39px;
    width: 38px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 0;
  }

  .search-icon {
    font-size: 20px;
    padding-top: 6px;
  }

  .search-input::placeholder {
    font-size: 13px;
    font-family: 'Lato';
    font-weight: 400;
  }
}
}
table {
width: 100%;
border: 1px solid rgb(223, 225, 229);
border-collapse:collapse;
}
// th {
//   padding: 10px;
//   color: rgb(99, 99, 99);
//   font-size: 13px;
//   font-weight: normal;
//   text-align: left;
//   position: sticky;
//   top: 0px;
//   background-color: rgb(242, 242, 242);
//   z-index: 1;
//   text-align: center;
//   border: 1px solid rgb(223, 225, 229);
//   font-family: 'Lato';
// }

// th:first-child {
//   width: 20px;
// }

// th:nth-child(2) {
//   width: 325px;
// }

.empty-data {
text-align: center;
padding: 10px 0;
font-family: 'Lato';
color: rgb(137, 137, 137);
}

.pagination-wrapper {
height: 40px;
border-bottom: 1px solid rgb(223, 225, 229);
border-left: 1px solid rgb(223, 225, 229);
border-right: 1px solid rgb(223, 225, 229);
display: flex;
justify-content: flex-end;
align-items: center;
}

.current-page {
margin: 0;
font-size: 12px;
font-family: 'Lato';
padding: 2px 10px;
background-color: #f2f2f2;
}

td {
padding: 10px;
  color: #636363;
  font-size: 13px;
  font-weight: normal;
  z-index: 1;
  text-align: center;
  border: 1px solid #dfe1e5;
  font-family: 'Lato';
}

tr:hover{
background-color: #f2f2f2;
}

.parent {
background-color: #f1f2f4;
min-height: 100vh;
}

.app-header {
  height: 44px;
  background-color: #ff632b;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    color: #ffffff;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    padding: 0 20px;
  }

  img {
    zoom: 0.75;
    padding: 0 15px;
  }

  .menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
   
    .router-link {
      text-decoration: none;
      padding: 0 15px;
      // color:#f1f2f4;
      font-size: 15px;
      cursor: pointer;
      display: inline;
      height: 44px;
      align-items: center;
      display: flex; 
      color: #FFF;
      
    }
    .router-btn:active{
        background-color: red;
    }
  }

  .right-hand-menu {
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    p {
      padding: 0;
      margin: 0;
      color: #fff;
      font-size: 14px;
    }

    .icons-white {
      color: #fff;
      padding: 0 5px;
      cursor: pointer;
    }
  }
}

.page-content {
  padding: 30px;
  background: #FFF;
  min-height: 80vh;
}


//Sidenavbar css

.admin-side {
  background-color: #f1f2f4;
  min-height: 100vh;
  display: flex;
  position: relative;

  .side-navbar {
    // height: 44px;
    background-color: #f25822;
    width: 7%;
    // display: flex;
    align-items: center;
    // margin-bottom: 10px;
  }
  .admin-content{
    padding: 30px;
    background: #FFF;
    min-height: 80vh;
    width: -webkit-fill-available;
  }


  .sidebar-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 44px;
    // display: flex;
    align-items: center;
   
    .router-link {
      text-decoration: none;
      padding: 0 15px;
      // color:#f1f2f4;
      font-size: 15px;
      cursor: pointer;
      display: inline;
      height: 44px;
      align-items: center;
      display: flex; 
      color: #FFF;
      
    }
    .router-btn:active{
        background-color: red;
    }
  }

  .right-hand-menu1 {
    position: absolute;
    bottom: 0;
    padding: 30px;


    .icons-white {
      color: #fff;
      padding: 0 5px;
      cursor: pointer;
    }
  }
}
.btn-primary-edit {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 25px;
  width:140px;
}

.btn-primary {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;margin: 5px;
  
}

.closeIcon{
  cursor: pointer;
}

.dialogTitle{
  flex: unset !important;
  margin:  unset !important; 
  padding-top: unset !important;
  padding-right: unset !important;
  padding-bottom: unset !important;
  padding-left: 15px !important;
}

.dialogContent{
  padding-bottom: 25px !important;

}
.primary-button {
  background-color: #ff632b !important;
}

.btn-primary {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;margin: 5px;
  
}

.closeIcon{
  cursor: pointer;
}

.dialogTitle{
  flex: unset !important;
  margin:  unset !important; 
  padding-top: unset !important;
  padding-right: unset !important;
  padding-bottom: unset !important;
  padding-left: 15px !important;
}

.dialogContent{
  padding-bottom: 25px !important;

}

.cardLayout {
  height: 290px;
  width: 255px;
  .contentCard {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 5px;
  }
  .imgContent {
    height: 100px;
    width: 100px;
  }
  .itemSpacing {
    margin: 5px;
  }
  .cardLayout:hover {
    border-color: purple;
  }

  cursor: pointer;
}
.cardLayout {
  height: 290px;
  width: 255px;
  .contentCard {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 5px;
  }
  .imgContent {
    height: 100px;
    width: 100px;
  }
  .itemSpacing {
    margin: 5px;
  }
  .cardLayout:hover {
    border-color: purple;
  }

  cursor: pointer;
}
.checkbox-label input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom{
   position: absolute;
   // top: 0px;
   // left: 0px;
   height: 24px;
   width: 24px;
   background-color: transparent;
   border-radius: 5px;
   border: 2px solid rgb(199, 14, 14);
}
.checkbox-label .checkbox-custom:after {
   position: absolute;
   content: "";
   left: 12px;
   top: 12px;
   height: 0px;
   width:0px;
   border-radius: 5px;
   border: solid rgba(245, 0, 0, 0.333);
   border-width: 0 3px 3px 0;
   -webkit-animation: rotate(0deg) scale(0);
   transform: rotate(0deg) scale(0) ;
   -ms-transform:  rotate(0deg) scale(0) ;
   opacity: 1;
}


.checkbox-label input:checked ~ .checkbox-custom {
   background-color: #17d67d;
   opacity: 1;
   border-radius: 5px;
   -webkit-transform: rotate (0deg) scale(1);
   -ms-transform: rotate (0deg) scale(1); 
   border: 2px solid #08ce54;
   transform: rotate (0deg) scale(1);
}

.checkbox-label input:checked ~ .checkbox-custom::after {
   -webkit-transform: rotate (45deg) scale(1); 
   -ms-transform: rotate (45deg) scale(1);
   transform: rotate (45deg) scale(1);
   opacity: 1;
   left: 8px;
   top: 3px;
   width: 6px;
   height: 12px;
   border: solid #FFF;
   border-width: 0 2px 2px 0;
   background-color: transparent;
   border-radius: 0;
}
.btn-primary {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;margin: 5px;
  
}

.closeIcon{
  cursor: pointer;
}

.dialogTitle{
  flex: unset !important;
  margin:  unset !important; 
  padding-top: unset !important;
  padding-right: unset !important;
  padding-bottom: unset !important;
  padding-left: 15px !important;
}

.dialogContent{
  padding-bottom: 25px !important;

}

.btn-primary-edit {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 25px;
  width:140px;
}




.input-text-field {
  width: 400px;
  .input-heading {
      margin: 8px 0;
  font-size: 13px;
  font-family: 'Lato';
  text-align: left;
  width: 100%;
  }

  input {
      height: 28px;
      min-width: 250px;
      padding: 0 5px;
      outline: none;
      width: 100%;
  }

  input::placeholder {
      font-family: 'Lato';
      padding: 0 5px;
  }

  .input-error-text {
      font-size: 12px;
      margin: 8px 0;
      width: 100%;
      color: red;
      font-family: 'Lato';
      text-align: left;
  }
}
.menuButton {
  line-height: unset !important;
  text-transform: unset !important;
  transition: unset !important;
  
}

.menu {
  opacity: 1;
  transform: none;
  transition: opacity 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 176ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 93px !important;
  left: 0px !important;
  transform-origin: 0px 77px;
 
 
}

/*Commented below line because of the*/
.MuiListItem-gutters {
  padding: 10px;
 
}

// .MuiMenu-list {
//     outline: 0;
//     border-bottom: solid 1px red !important;
//     border-left: solid 1px red;
//     border-right: solid 1px  tomato;

// }

.listing-data-wrapper {
  padding: 16px 30px;
  position: relative;
  box-shadow: rgb(223 225 229 / 40%) 0px -1px 0px 0px, rgb(223 225 229) 0px 1px 0px 0px;
  background-color: rgb(249, 249, 249);
  margin: 15px 0;
  display: flex;

  .nav-links {
    text-decoration: none;
    color: #000;
  }

  // .nav-links:last-child {
  //   margin-left: 20px;
  // }

  .item-wrapper {
    width: 150px;
    height: 50px;
    border: 1px solid rgb(223, 225, 229);
    background-color: rgb(255, 255, 255);
    padding: 7px 12px 4px 11px;
    cursor: pointer;
    h1 {
      margin: 0;
      font-size: 17px;
      font-family: 'Lato';
      font-weight: 400;
    }
    p {
      padding: 0;
      margin: 5px 0;
      font-size: 14px;
      font-family: 'Lato';
    }
  }
}
.listing-data {
  padding: 16px 30px;
  position: relative;
  box-shadow: rgb(223 225 229 / 40%) 0px -1px 0px 0px, rgb(223 225 229) 0px 1px 0px 0px;
  background-color: rgb(249, 249, 249);
  margin: 15px 0;
  display: flex;

  .nav-links {
    text-decoration: none;
    color: #000;
  }



  .item-wrapper {
    width: 150px;
    height: 50px;
    border: 1px solid rgb(223, 225, 229);
    background-color: rgb(255, 255, 255);
    padding: 7px 12px 4px 11px;
    cursor: pointer;
    h1 {
      margin: 0;
      font-size: 17px;
      font-family: 'Lato';
      font-weight: 400;
    }
    p {
      padding: 0;
     
      font-size: 14px;
      font-family: 'Lato';
    }
  }
}

.listing-data {
  padding: 16px 30px;
  position: relative;
  box-shadow: rgb(223 225 229 / 40%) 0px -1px 0px 0px, rgb(223 225 229) 0px 1px 0px 0px;
  background-color: rgb(249, 249, 249);
  margin: 15px 0;
  display: flex;

  .nav-links {
    text-decoration: none;
    color: #000;
  }



  .item-wrapper {
    width: 150px;
    height: 50px;
    border: 1px solid rgb(223, 225, 229);
    background-color: rgb(255, 255, 255);
    padding: 7px 12px 4px 11px;
    cursor: pointer;
    h1 {
      margin: 0;
      font-size: 17px;
      font-family: 'Lato';
      font-weight: 400;
    }
    p {
      padding: 0;
     
      font-size: 14px;
      font-family: 'Lato';
    }
  }
}

.verification-status-card {
  width: 113.4px;
  padding: 28px 40px;
  border: 1px solid rgb(223, 225, 229);
  background-color: white;
  text-align: center;
  font-family: 'Lato' ;
  position: relative;
  margin-top: 30px;
  margin-right: 20px;

  .logo-area {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(223, 225, 229);
    border-radius: 100px;
    position: absolute;
    top: -25px;
    background: #fff;
    left: 37%;
    zoom: 0.8;
  }

  .status {
    margin: 5px 0;
    padding: 0 5px;
    width: 100%;
    font-size: 14px;
    background-color: #8b8888;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    font-family: 'Lato';
  }
  a {
    font-size: 12px;
    text-decoration: none;
    color: rgb(51, 146, 243);
  }
}

.card-heading {
  margin: 0;
  font-size: 14px;
  text-align: center;
  padding: 0;
  font-family: 'Lato';
  font-weight: 500;
}

.btn-primary-edit {
  padding: 5px 20px;
  background: #ff632b;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 25px;
  width:140px;
}
.formImage {
  margin: 0 auto;
  display: flex;
  height: 155px;
}

.product-title {
  font-family: 'Lato';
  text-align: center;
  color: #ff8e33;
  font-weight: 500;
}
.product-title-AddProduct {
  font-family: 'Lato' !important;
  font-weight: 500 !important;
  font-size: 25px !important;
  padding-left:15px  ;
  padding-top: 5px;
}

.stock-label {
  color: #ff8e33;
  font-family: 'Lato' !important;
  display: flex;
  margin-top: 15px !important;
}

.btn-primary {
  padding: 5px 20px;
  background: #ff8e33;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  margin: '5px';
}

.btn-update {
  display: grid;
}
.updatebox {
  height: 75px !important;
  width: 99%;
  /* margin: 5px; */
  margin-bottom: 9px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 6px !important ;
  outline-color: #f88e34;
  font-family: 'Lato';
  padding: 5px;
}
.alertupdatebox {
  height: 75px !important;
  width: 245px;
   margin: 5px; 
  margin-bottom: 9px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 6px !important ;
  outline-color: #f88e34;
  font-family: 'Lato';
  padding: 5px;
}

.formImage {
  margin: 0 auto;
  display: flex;
  height: 155px;
}


.product-title-AddProduct {
  font-family: 'Lato' !important;
  font-weight: 500 !important;
  font-size: 25px !important;
  padding-left:15px  ;
  padding-top: 5px;
}

.stock-label {
  color: #ff8e33;
  font-family: 'Lato' !important;
  display: flex;
  margin-top: 15px !important;
}

.btn-primary {
  padding: 5px 20px;
  background: #ff8e33;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  margin: '5px';
}

.btn-update {
  display: grid;
}
.updatebox {
  height: 75px !important;
  width: 99%;
  /* margin: 5px; */
  margin-bottom: 9px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 6px !important ;
  outline-color: #f88e34;
  font-family: 'Lato';
  padding: 5px;
}
.alertupdatebox {
  height: 75px !important;
  width: 245px;
   margin: 5px; 
  margin-bottom: 9px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 6px !important ;
  outline-color: #f88e34;
  font-family: 'Lato';
  padding: 5px;
}
.addproduct-form-wrapper{
  width: 50%;
  margin: 20px auto;
  .add-product-content{ 
    padding: 20px;
    text-align: center;
    .product-title {
      font-family: 'Lato';
      text-align: center;
      color: #ff632b;
      font-weight: 500;
    }
  }
}

.formImage {
  margin: 0 auto;
  display: flex;
  height: 155px;
}

.product-title {
  font-family: 'Lato';
  text-align: center;
  color: #ff8e33;
  font-weight: 500;
}
.product-title-AddProduct {
  font-family: 'Lato' !important;
  font-weight: 500 !important;
  font-size: 25px !important;
  padding-left: 15px;
  padding-top: 5px;
}

.stock-label {
  color: #ff8e33;
  font-family: 'Lato' !important;
  display: flex;
  margin-top: 15px !important;
}

.btn-primary {
  padding: 5px 20px;
  background: #ff8e33;
  color: #fff;
  font-family: 'Lato';
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  margin: "5px";
}

.btn-update {
  display: grid;
}
.updatebox {
  height: 75px !important;
  width: 99%;
  /* margin: 5px; */
  margin-bottom: 9px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 6px !important ;
  outline-color: #f88e34;
  font-family: 'Lato';
  padding: 5px;
}
.alertupdatebox {
  height: 75px !important;
  width: 245px;
  margin: 5px;
  margin-bottom: 9px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 6px !important ;
  outline-color: #f88e34;
  font-family: 'Lato';
  padding: 5px;
}
.addproduct-form-wrapper {
  width: 100%;
  margin: 0;
  .add-product-content {
    padding: 20px;
    text-align: center;
    .text-header-products {
      color: #ff632b;
      font-size: larger;
      font-family: 'Lato';
      font-weight: 500;
      display: flex;
      margin: 10px 0 20px;
    }
    .catogeries-wrapper {
      margin: 15px;
      .MuiTypography-body1 {
        cursor: pointer;
        background-color: #ff632b !important;
        padding: 4px 8px;
        color: #fff;
        font-family: 'Lato';
        border-radius: 5px;
      }
    }
    .txt-label-catogeries {
      font-size: 14px;
      font-family: 'Lato';
      margin-right: 125px;
      color: #898989;

      text-align: left;
    }
    .error-text-category {
      width: fit-content;
      margin: 0;
      padding: 3px;
      font-size: 11px;
      text-align: left;
      color: red;
      font-family: 'Lato';
      margin-left: auto;
    }
  }

  .layout-flex {
    display: flex;
    .input-field-layout-left {
      width: 60%;
      text-align: left;
      border-right: 1px solid #ffd9ba;
      padding-right: 20px;
      margin-right: 20px;
      .product-dropdown-wrapper{
        margin: 15px;
      }
    }
    .input-field-layout-right {
      width: 40%;
      text-align: left;

      .defaultimage {
        border: 2px dashed #ddd;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        .img-ic {
          color: #aaa;
          font-size: 80px;
        }
      }
    }
    .product-image {
      width: 100%;
    }
  }
  .btn-product-add {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 40px;
  }
}
.addProduct-popup {
  .MuiDialog-scrollPaper {
    justify-content: flex-end;
  }

  .MuiDialog-paperWidthSm {
    max-width: 100%;
  }

  .MuiDialog-paperScrollPaper {
    display: block;
    max-height: 100%;
    height: 100vh;
    margin: 0;
    width: 55vw;
  }
  .MuiBackdrop-root {
    background: transparent;
  }
}
.marginlr-addproduct {
  margin-top: 120px;
}

.scroll-bar {
  margin: 15px;
  display: flex;
  align-items: center;
  height: 80px;

  .brand-scrollbar {
    // background-color: #ff632b;
    border: 1px solid #ff632b;
    // overflow: auto;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
  }
  .txt-label-brand {
    font-size: 14px;
    font-family: 'Lato';
    width: 71%;
    color: #898989;

    text-align: left;
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-button {
    width: 2px;
    height: 2px;
  }

  div {
    box-sizing: border-box;
  }

  body {
    background: #111;
  }

  .horizontal-scroll-wrapper {
    position: absolute;
    display: block;
    top: 45px;
    left: 414px;
    width: 80px;
    max-height: 260px;
    margin: 0;
    border: 1px solid #ff632b;
    margin: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    transform: rotate(
-90deg
) translateY(-80px);

  }
  
  .horizontal-scroll-wrapper > button {
    display: block;
    padding: 5px;
    background: #f25822;
    color: #fff;
    font-family: 'Lato';
    font-size: 13px;
    border-radius: 10px;
    box-shadow: 1px 1px 0px 2px #f2582257;

    transform: rotate(90deg);
    transform-origin: right top;
    &:hover{
      color: black;
    }
  }
  

  .squares {
    padding: 60px 0 0 0;
  }

  
  .squares > button {
    width: 60px;
    height: 60px;
    margin: 10px;
    padding: 19px 1px;
    text-align: center;
    border: none;
    &:active{
      background-color: #FFF;
      color: #f25822;
    }
  }
  }

  .formImage {
    margin: 0 auto;
    display: flex;
    height: 155px;
  }
  
  .product-title {
    font-family: 'Lato';
    text-align: center;
    color: #ff8e33;
    font-weight: 500;
  }
  .product-title-AddProduct {
    font-family: 'Lato' !important;
    font-weight: 500 !important;
    font-size: 25px !important;
    padding-left:15px  ;
    padding-top: 5px;
  }
  
  .stock-label {
    color: #ff8e33;
    font-family: 'Lato' !important;
    display: flex;
    margin-top: 15px !important;
  }
  
  .btn-primary {
    padding: 5px 20px;
    background: #ff8e33;
    color: #fff;
    font-family: 'Lato';
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    height: 40px;
    margin: '5px';
  }
  
  .btn-update {
    display: grid;
  }
  .updatebox {
    height: 75px !important;
    width: 99%;
    /* margin: 5px; */
    margin-bottom: 9px !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 6px !important ;
    outline-color: #f88e34;
    font-family: 'Lato';
    padding: 5px;
  }
  .alertupdatebox {
    height: 75px !important;
    width: 245px;
     margin: 5px; 
    margin-bottom: 9px !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 6px !important ;
    outline-color: #f88e34;
    font-family: 'Lato';
    padding: 5px;
  }
  .AddPromotionForm {
    //padding: 10px 20px;
    background: #fff;
    margin: 50px auto;
    border-radius: 5px;
    box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px,
      rgb(34 34 34 / 30%) 0px 1px 18px 0px;
      width: 120vh;
      
  }
  
  
  .backgroundForm{
      background-color: #ffffff;
    min-height: 100vh;
    overflow: auto;
    
  }
  
  .loaderGif {
    width: 30px;
    background: transparent;
}

.hide-element {
  display: none;
}

.MuiList-padding {
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.MuiMenuItem-root {
  padding: 5px  !important;
}

.location-layout-wrapper {
  .No-Active-card-wrapper {
    left: 2% !important;
  }
}