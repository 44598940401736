.additional-contact-informations {
    .heading {
        font-size: 16px;
    }

    .contacts-header {
        margin: 0 0 15px 0;
        .flex {
            .btn-action {
                margin: 0;
            }
        }

    }

    .contacts-table {
        width: 46.875vw;
    }

    table {
        tbody {
            tr {
                td {
                    .btn-action {
                        margin: 0;
                        font-size: 12px;
                        height: 22px;
                    }
                }
            }
        }
    }
}
.contacts-container {
    width: 45vw;
}