.btn-action {
    background: #f25822;
    font-family: 'Lato';
    outline: transparent;
    border: none;
    height: 30px;
    padding: 0px 15px;
    color: #FFF;
    border-radius: 3px;
    cursor: pointer;
    width: fit-content;
  }

  .outlined {
    background: #FFF;
    font-family: 'Lato';
    outline: transparent;
    border: 1px solid #ff632b;
    height: 30px;
    padding: 0px 15px;
    color: #ff632b;
    border-radius: 3px;
    cursor: pointer;
  }

  .btn-action:hover{
    box-shadow: 1px 1px 5px 1px #f25822;
    transition: ease-in 0.25s;
  }