.bad-wrp {
    position: absolute;
    top: -22px;
    right: 0px;
}
.plus-icon {
    position: absolute;
    z-index: 999;
    left: -5px;
    top: 14px;
}

  .product-col {
    display: flex;

    .pimg-wrap {
        width: 65px;
        height: 65px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        margin-right: 19px;
        img.product-img {
            width: auto;
            height: 50px;
            object-fit: contain;
        }
    }

    .pcontent-wrap {
        display: flex;
        align-items: center;
        justify-content: left;
        line-height: 20px;
        word-break: break-word;
        color: #121212;
    }
}