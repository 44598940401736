.signupForm {
   h1{
    margin: 20px 0;
    font-size: 20px;
    text-align: center;
    font-family: 'Lato';
    font-weight: 500;
   }
}

.name-field-margin {
    margin-right: 10px;
}

.signupForm{
    width: 450px;
    padding: 10px 20px;
    background: #FFF;
    margin: 50px auto;
    border-radius: 5px;
    box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px, rgb(34 34 34 / 30%) 0px 1px 18px 0px;
  }

