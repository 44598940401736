.legal-details-Header {
    height: 35px;
    margin: 0;
    box-shadow: 0px 1px 1px #00000029;
    background: #0000000d;
    display: flex;
    padding: 8px;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    p {
        font-weight: 600;
        color: #090a0c;
        padding: 0 5px;
    }
    
}
.inventory-management-page {
    padding: 30px;
    background: #fbfbfb;
    height: calc(100vh - 60px);
    .export-button-wrapper {
        background: #d9d9d9;
        color: #000000;
        border: 0;
        width: 100px;
        font-family: "Lato";
        height: 30px;
        border-radius: 5px;
        font-weight: 600;
    }
    .Inventory-table-layout {
        border: 1px solid #f5f5f5;
        background-color: #ffffff;
        box-shadow: 0px 1px 5px #00000029;
        margin: 28px -4px;

        .Inventory-table-header {
            display: flex;
            align-items: center;
            justify-content: left;
            height: 40px;

            .inventory-filters {
                .inventory-selector {
                    color: #090a0c;
                    font-size: 14px;
                    margin: 0 20px;
                    padding: 12px;
                    border-bottom: 2px solid #ff6522;
                }
            }
            .inventory-products-search {
                margin: 0 auto;
                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font-family: "Lato";
                    color: #B8B8B8;
                    font-size: 13px;
                  }
                  ::-moz-placeholder { /* Firefox 19+ */
                    font-family: "Lato";
                   
                  }
                .products-search{
                    width: 348px;
                    height: 20px;
                    border: 1px solid #B8B8B8;
                }
            }
            .products-filters{
                display: flex;
                align-items: center;
            }
        }
    }
    .inventory-products-table{
        th {
            background-color: transparent;
            height: 40px;
            font-size: 14px;
            font-weight: bold;
            &:first-child {
                width: auto;
            }
            &:nth-child(2) {
                width: auto;
            }
            &:nth-child(5) {
                width: auto;
                
            }
        }
    }
}


.search-filter-products-dialog .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    width: 480px !important;
    position: absolute;
    bottom: auto;
}

.filtercard {
    bottom: 363px !important;
}

.map-locations-column {
    cursor: pointer;
}
