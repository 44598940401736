.login-page-wrapper {
  background: #ffddbc;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;

.login-page {
  display: flex;
  width: 60%;
  background: #fff;
  height: 80vh;
}

.left-content {
  width: 50%;
  border: 1px solid #b8b8b8;
  align-items: center;
  text-align: center;
  background: #f5f5f5;
  display: flex;
  .left-content-align {
    margin: 0 auto;
  }

  .poorvika-svg-logo {
    width:  9.53vw;
    height: 3.9vh;
  
  }
  .login-illustration {
    width: 100%;
    height: 50vh;
  }
  .seller-portal-name-wrapper {
    color: #455a64;
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.right-content {
  width: 50%;
  text-align: center;
  border: 1px solid #b8b8b8;
  padding: 30px;
  justify-content: center;
  align-items: center;
  border-left: 0;
  .sign-in-name-wrapper {
    color: #090A0C;
    font-size: 20px;
    margin-bottom: 10px;
    font-family: 'Lato';
    font-weight: 600;
  }
  .privacy-wrapper{
    font-size: 11px;
    margin-top: 8%;
    .privacy-content{
      color: #2874F0;
      cursor: pointer;
    }
  }
}
 
  .form-container {
    width: 400px;
    text-align: center;
  }
  .seller-account-name{
    font-size: 14px;
    color: #090A0C;
    margin: 0;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ff8e33 !important;
}

.breakLine {
  width: 400px;
}

.btn-sso-login {
  width: 100px;
  background-color:linear-gradient(#ff7a00, #ff2c2c);
  padding: 10px;
  border: #ff632b;
  color: #f5f5f5;
  font-weight: bolder;
  cursor: pointer;
}

.registration-seller {
  padding: 0;
  color: #606060;
  font-size: 11px;
  margin-top: 1%;
  a, 
  .btn-link {
    text-decoration: none;
    color: #2874F0;
    font-family: 'Lato';
    font-size: 12px;
    border: none;
  }
}

.login-type-selector {
  width: 100%;
  display: flex;
  justify-content: center;

  .btn-login-type {
    width: 200px;
    /* margin: 0 10px; */
    height: 30px;
    background: #fff;
    border: none;
    font-family: 'Lato';
    font-size: 15px;
    cursor: pointer;
  }

  .active-tab {
    border-bottom: 3px solid #ff632b;
  }
}
