.kyc-signature-container {
    margin-bottom: 15px;
    .signature-header {
        margin: 5px 0 10px 8px;
        font-size: 16px;
        color: #818181;
    }

    .signature-panel {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        /* border: 1px solid; */
        border: 1px solid #e8e9ec;
        border-radius: 5px;
        margin: 0 10px;
        img{ 
            height: 30px;
        }
    }

}