.rignt-seller-content {
    width: 100%;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px 0 0;
    cursor: pointer;
    p {
        margin: 0;
    }
    .header-down-icon {
        color: #b8b8b8;
        transform: rotate(90deg);

        &:active {

        }
    }

    .Account-ic-header {
        color: #b8b8b8;
        padding: 0 5px;
        cursor: pointer;
        font-size: 38px;
    }

    .name-email-wrapper {
        .header-seller-name {
            margin: 0;
            font-size: 12px;
            color: #090a0c;
            text-align: left;
        }
        .header-seller-email {
            margin: 0;
            color: #818181;
            font-size: 12px;
        }
    }
    .login-menu:active {
        .header-down-icon {
            transform: rotate(90deg);
        }
    }
}
