.stepper-main-wrapper {
  width: 75%;
  text-align: center;
  margin: 44px auto;
  .MuiStepIcon-root.MuiStepIcon-active {
    color: #f44336 !important;
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #f44336 !important;
  }
}

.styled-table {
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 0.9em;
  font-family: "Lato";
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #3f51b5d4;
  color: #ffffff;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  text-align: center;
  font-weight: 400;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.btn-action {
  margin: 10px 30px;
}
.right-hand-menu {
  a {
    display: flex;
    text-decoration: none;
    .icons-white {
      color: #fff;
      padding: 0 5px;
      cursor: pointer;
    }
    .logout-btn {
      display: block;
      color: #fff;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
}
