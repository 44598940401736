.product-list-page {
    .bread-crumb-layout {
        .MuiTypography-root .MuiBreadcrumbs-root .mt-2 .MuiTypography-body1 .MuiTypography-colorTextSecondary {
            margin: 10px 30px;
            a {
                text-decoration: none;
            }
        }
        .MuiBreadcrumbs-ol {
            margin: 10px 30px;
        }
        .bread-crumb-navlink {
            padding: 0 15px;
            height: 30px;
            box-shadow: 0px 1px 5px #00000029;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 5px;
            font-weight: 800;
            border: 1px solid #b8b8c8b5;
        }
    }
    .product-list-layout {
        display: flex;
        padding: 20px;
        height: calc(100vh - 100px);
        overflow: auto;
        .pd-sidemenu {
            width: 272px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 1px 5px #00000029;
            border-radius: 5px 5px 0px 0px;
            border-radius: 10px;
            height: fit-content;
            padding-bottom: 10px;
            .select-content {
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                padding: 12px;
                &.active {
                    background-color: #f5f5f5;
                    .select-indicator {
                        font-size: 14px;
                        color: #000000;
                        font-family: "Lato";
                        font-weight: 600;
                    }
                    .keyboard-right-icon {
                        color: #000000;
                    }
                }
                .select-indicator {
                    font-size: 14px;
                    color: #000000;
                    font-family: "Lato";
                    font-weight: 500;
                }
                .keyboard-right-icon {
                    color: #b8b8b8;
                }
            }
        }
        .pd-sidemenu-container {
            width: calc(100% - 272px);
        }
    }
}
