.notifications-page {
    background-color: #fbfbfb;

    .notifications-page-wrapper {
        display: flex;
        height: 100vh;
        margin: 25px 50px;
        
        .filter-wrapper {
            width: 25%;
            padding: 0 20px;
            height: 50vh;
            display: flex;
            align-items: flex-start;
            box-shadow: 0px 5px 5px #00000029;


            .filter-card {
                padding: 0 10px 7px 10px;
                .title-and-reset {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 50px;
                    
                    p {
                        width: 50%;
                        margin: 0;
                        font-size: 14px;
                        color: #a4a4a4;
                    }

                    p:nth-child(2) {
                        span {
                            zoom: 0.65;
                            padding: 0 5px;
                        }
                        text-align: end;
                        color: #1ea7f2;
                        font-weight: 600;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        cursor: pointer;
                    }
                }

                .filter-sub-section {
                    border-bottom: 1px solid #b8b8b86e;
                    margin: 0 0 10px 0;
                    h1 {
                        font-size: 11px;
                        margin: 0;
                        font-weight: 500;
                        color: #b8b8b8;
                        text-transform: uppercase;
                    }

                    .date-range-filter {
                        padding: 0 8px;
                        input {
                            width: 100%;
                            height: 25px;
                            margin: 15px 0;
                        }

                        input::placeholder {
                            color: #b8b8b8;
                            padding: 0 5px;
                        }
                        
                    }

                    
                }
            }
        }

        .notification-wrapper {
            width: 75%;
            padding: 0 25px;

            .notification-menu {
                background: #FFF;
                box-shadow: 3px 3px 6px 2px #cecece;
                border-radius: 7px;
                
                .header-and-actions {
                    width: 100%;
                    .notification-header {
                        width: 50%;
                    }

                    .action {
                        padding: 0 10px;
                    }
                    
                }

                
            }
            .notification-list {
                height: 60vh;
            }

            .notification-items {
                .notification-title-and-description {
                    width: 56%;
                }
            }
        }

    }




}