.accounts-list-page {
   padding: 25px;
   .search-wrapper {
      display: none;
      margin-left: auto;
      .search-input {
         height: 30px;
         border: 1px solid #ff632b8c;
         font-family: "Lato";
         outline: transparent;
         padding: 0 10px;
         width: 200px;
         border-top-left-radius: 3px;
         border-bottom-left-radius: 3px;
      }

      .search-icon-button {
         height: 32.25px;
         /* border: 2px solid; */
         background: #ff632b;
         font-family: "Lato";
         outline: transparent;
         border: none;
         /* height: 30px; */
         padding: 0px 15px;
         color: #fff;
         /* border-radius: 3px; */
         cursor: pointer;
         border-top-right-radius: 3px;
         border-bottom-right-radius: 3px;
      }
   }

   .chips-container {
      display: flex;
      justify-content: left;
   }
   .accounts-table-wrapper {
      .all-products-pagination {
         margin-top: 50px;
         .MuiPagination-ul {
            justify-content: flex-end;
         }
      }
      .accounts-list-table {
         border: 1px solid #f5f5f5;
         background-color: #fff;
         box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
         th,
         td {
            border-right: none;
            border-left: none;
         }
         td {
            padding: 20px;
            color: #636363;
            font-size: 13px;
            font-weight: 400;
            z-index: 1;
            text-align: center;
         }
         th {
            padding: 20px 10px;
            color: #090a0c;
            font-size: 13px;
            font-weight: 800;

            top: 0;
            z-index: 1;
            text-align: center;
            font-family: lato;
            border: 0;
            background-color: transparent;
         }
         th:first-child,
         th:nth-child(2) {
            width: auto !important;
         }
         td {
            .btn-action {
               margin: 0 !important;
               height: 30px;
               text-transform: uppercase;
            }
         }
      }
   }
   .sorting-table-accounts-list {
      input {
         height: 30px;
         border: 1px solid #ff632b8c;
         font-family: "Lato";
         outline: transparent;
         padding: 0 10px;
         width: 200px;
         border-top-left-radius: 3px;
         border-bottom-left-radius: 3px;
      }
      th:nth-child(1) {
         width: 219px;
      }
      th:nth-child(2) {
         width: 308px;
      }
      th:nth-child(3) {
         width: 325px;
      }
      th:nth-child(4) {
         width: 325px;
      }
      th:nth-child(5) {
         width: 200px;
      }
      th:nth-child(6) {
         width: 385px;
      }
      th {
         background-color: transparent;
      }
      .btn-action {
         margin: 0px 20px !important;
      }
   }
}
.pagination-hide {
   display: none;
}

.filter-buttons {
   width: 200px !important;
   display: flex;
}

.search-filter-Accounts-dialog {
   text-align: center;
   .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
      width: 480px !important;
      position: absolute;
      top: 130px;
   }

   .MuiFormControl-fullWidth {
      width: 60%;
   }
   .MuiDialog-scrollPaper {
      justify-content: flex-end;
      margin-top: 60px;
   }

   .MuiDialog-paperWidthSm {
      max-width: 100%;
   }

   .MuiDialog-paperScrollPaper {
      display: block;
      max-height: 100%;
      height: auto;
      margin: 0;
      width: 40vw;
   }
   .MuiBackdrop-root {
      background: transparent;
   }
   .MuiDialogActions-root.MuiDialogActions-spacing {
      justify-content: center;
   }
   .products-title {
      text-align: center;
      .MuiTypography-root {
         font-size: 15px;
         font-family: "Lato";
         font-weight: 600;
      }
   }
   .btn-action {
      background: #fff2ed;
      color: #f25822;
      font-size: 15px;
      font-weight: 600;

      font-family: "Lato";
      outline: transparent;
      border: none;
      height: 35px;
      padding: 0px 15px;
      border-radius: 3px;
      cursor: pointer;
      width: 100px;
      box-shadow: 0px 3px 6px #00000052;
   }
   .outlined {
      // background: #fff2ed;
      color: #f25822;
      font-size: 15px;
      font-weight: 600;

      font-family: "Lato";
      outline: transparent;
      border: none;
      height: 35px;
      padding: 0px 15px;
      border-radius: 3px;
      cursor: pointer;
      width: 100px;
      box-shadow: 0px 3px 6px #00000052;
   }
}
