.app-headers {
    height: 60px;
    background-color: #fff7f0;
    display: flex;
    align-items: center;
    position: relative;
    .title-status-wrapper {
        display: flex;
        align-items: center;
        .header-status {
            margin: 0;
            padding: 0 20px;
            font-size: 14px;
            color: #982600;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
    .header-logout-wrapper {
        right: 15px;
        position: absolute;
        a {
            color: #e48412;
        }
    }
    .header-title {
        color: #090a0c;
        font-size: 16px;
        margin: 0;
        font-weight: 800;
    }
    .header-back-icon {
        color: #b8b8b8;
        border: 1px solid #b8b8b8;
        border-radius: 5px;
        margin: 0 30px;
        width: 18px;
        opacity: 0;
        // &:hover {
        //     color: #090a0c;
        //     cursor: pointer;
        // }
    }
}
