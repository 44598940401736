.details-confirmation-page {
    width: 60%;
    margin: 0 auto;
    .agree-tnc {
        font-family: 'Lato';
        font-size: 14px;
    }
    .name-and-details {
        display: flex;
        .title-text {
            font-size: 14px;
            font-family: 'Lato';
            margin-right: 15px;
            color: #898989;
            width: 50%;
            text-align: left;
            text-transform: capitalize;
        }
        .value-text {
            font-size: 14px;
            font-family: 'Lato';
            margin-right: 15px;
            color: #000;
            width: 50%;
            text-align: left;
        }
    }
}