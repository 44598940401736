.billing-and-taxing-form-page {
    width: 100%;
    .billing-and-taxing-form {
        width: 600px;
        margin: 0 auto;
        // padding: 0 60px;
        .input-with-labels .input-and-error .input-field-box[type="checkbox"] {
            float: left;
            width: auto;
        }
    }

    .bank-not-found {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        a {
            text-decoration: none;
            font-size: 14px;
            font-family: "Lato";
            color: #ff632b;
            .info-ic {
                font-size: 15px;
                padding-right: 2px;
            }
        }
    }

    .sub-text {
        font-size: 16px;
        font-family: "Lato";
        color: #ff632b;
        margin: 0;
    }
}
