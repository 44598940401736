// .btn-action {
//     background: #ff8e33;
//     font-family: 'Lato';
//     outline: transparent;
//     border: none;
//     height: 30px;
//     padding: 0px 15px;
//     color: #FFF;
//     border-radius: 3px;
//     cursor: pointer;
//   }

  .chip-btn {
    background: #FFF;
    font-family: 'Lato';
    outline: transparent;
    border: 1px solid #ff632b;
    height: 30px;
    padding: 0px 15px;
    color: #ff632b;
    border-radius: 25px;
    cursor: pointer;
    margin: 0 10px;
  }

  .chip-btn-bg {
    background: #f25822;
    font-family: 'Lato';
    outline: transparent;
    border: 1px solid #ff632b;
    height: 30px;
    padding: 0px 15px;
    color: #FFF;
    border-radius: 25px;
    cursor: pointer;
    margin: 0 10px;
  }
  .marginlr{
    margin : 10px 0;
  }