.login-btn {
  width: 11.68vw;
  height: 5.94vh;
  background: linear-gradient(#ff7a00, #ff2c2c);
  color: #ffffff;
  margin-top: 15%;
  border: none;
  border-radius: 5px;
  font-family: 'Lato';
  font-weight: 600;
  cursor: pointer;
  // opacity: 100%;
}
.forgot-password-area {
  text-align: right;
  .forgot-password {
    width: 50%;
    text-align: end;
    text-decoration: none;
    font-family: 'Lato';
    color: #606060;
    font-size: 11px;
  }
}
.remember-password {
  width: 50%;
}
.credential-fields {
  width: 400px;
  margin: 15px !important;

  .MuiInputLabel-formControl {
    font-size: 12.5px;
    font-family: 'Lato';

    &.MuiFormLabel-filled {
      font-size: 14px;
      color: #ff8e33;
    }
    &.Mui-focused {
      font-size: 14px;
      color: #ff8e33;
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ff8e33 !important;
  }
}

.loginForm-container {
  max-width: 100% !important;
  flex-basis: 100% !important;
  .login-label-wrapper {
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    font-size: 13px;
    color: #b8b8b8;
  }
  .label-hide {
    visibility: hidden;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    font-size: 13px;
    color: #b8b8b8;
  }
  .login-id-label {
    opacity: 0;
  }
}
// .password-wrapper{
//   position: relative;
//   display: flex;
//   margin-bottom: 14px;
// }

.loginForm-container {
  width: 300px;
  margin: 0 auto;
  margin-top: 10%;
  .form-group {
    position: relative;
  }
  label {
    float: left;
  }
  .row input:focus + label {
    color: red;
  }
  .password-ip {
    position: relative;

    .password-toogle-ic {
      position: absolute;
      z-index: 9;
      color: #a2a2a2;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 18px;
    }
  }
  .login-password-field-box {
    padding: 6px;
    border-radius: 2px;
    border: 1px solid #8d8d8da8;
    font-family: 'Lato';
    width: 100%;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    outline: transparent;
  }

  .login-input-field-box {
    padding: 6px;
    border-radius: 2px;
    border: 1px solid #8d8d8da8;
    font-family: 'Lato';
    width: 100%;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    outline: transparent;
   
  }
}

// .email-ip{
// input:active{
//   // background: #222222;
//   .login-id-label{
//     display: block;
//   }

// }

// }

.input-text {
  background-color: white;
  -webkit-appearance: none;
  border: 1px solid #cccccc;
  margin-bottom: 8px;
  padding: 8px 4px;
  position: relative;
  width: 100%;
  z-index: 3;
}

.label-helper {
  position: absolute;
  opacity: 0;

  bottom: 0;
  left: 0;
  z-index: 1;
}

.label-password {
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.login-input-field-box:focus + .label-helper {
  bottom: 95%;
  font-family: 'Lato';
  font-size: 11px;
  color: #b8b8b8;
  line-height: 1;
  opacity: 1;
  padding: 8px 0;
}
.login-password-field-box:focus + .label-password {
  bottom: 95%;
  line-height: 1;
  opacity: 1;
  padding: 8px 0;
  font-family: 'Lato';
  font-size: 11px;
  color: #b8b8b8;
}
.email-password-height {
  height: 50px;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: 'Lato';
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: 'Lato';
 
}