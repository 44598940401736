body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
    font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:'Lato', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-element {
  display: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.flex50 {
  width: 50%;
}

.flexEnd {
  justify-content: flex-end;
}

/* Dependent class - Material UI dialog width limiting */
.MuiDialog-paperWidthSm {
  max-width: none !important;
}

.clr-warning {
  color: red
}

.btn {
  cursor: pointer;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

/*Custom Radio button color*/
.Mui-checked {
  color: #f25822 !important;
}

/* Disabling cusor manipulation */
.cursor-notAllow {
  cursor: not-allowed;
  pointer-events: none;
} 