.legal-details-page {
    width: 100%;
    display: flex;
    .legal-layout-wrapper {
        width: 447px;
        padding: 0 10px;

        .legal-details-Header {
            height: 35px;
            margin: 0;
            box-shadow: 0px 1px 1px #00000029;
            background: #0000000d;
            display: flex;
            padding: 8px;
            align-items: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 8px 11px;
            justify-content: space-between;

            p {
                font-weight: 600;
                color: #090a0c;
                padding: 0 5px;
            }
            .edit-btn-wrapper {
                text-decoration: none;
                font-family: "Lato";
                color: #0073d3;
                font-size: 12px;
                font-weight: 400;
            }
        }
        .legal-details-content {
            // height: 32.1vh;
            background: #ffff;
            box-shadow: 0px 1px 5px #00000029;
            padding-bottom: 20px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 0 8px 10px;
            .save-btn-wrap {
                margin: 10px;
                text-align: center;
            }
            .form-data-hide {
                display: none;
            }
        }
    }
}

.profile-sub-heading {
    font-weight: 600;
    color: #090a0c;
    padding: 0 5px;
}