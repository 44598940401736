.legal-information-form-page {
  .legal-document {
    width: 180px;
    margin-bottom: 20px;
  }

  .legal-information-form {
    width: 500px;
    margin: 0 auto;

    .form-subtitle {
      font-family: "Lato";
      font-size: 16px;
      color: #ff632b;
    }
    .input-with-labels {
      margin-left: 0;
      .input-and-error {
        width: 96%;
      }
    }
    .input-with-dropdown {
      margin-left: 0;
    }
  }
}
.otp-verify-wrapper {
  display: flex;
  margin: 15px 0;
  .txt-label {
    font-size: 14px;
    font-family: "Lato";
    margin-right: 8px;
    color: #898989;
    width: 50%;
    text-align: left;
  }
  .btn-get-otp {
    width: 18%;
    font-size: 12px;
    color: green;
    font-family: "Lato";
    border: 1px solid green;
    padding: 6px;
    cursor: pointer;
  }
  .input-field-box {
    padding: 6px;
    border-radius: 2px;
    border: 1px solid #8d8d8da8;
    font-family: "Lato";
    width: 60%;
  }
}
