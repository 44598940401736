.accounts-verification-wizard {
    padding: 25px;
}
.accounts-verification-wizard-confirm {
    display: flex;
}
.accordian-wrapper {
}
.accordian-hide {
    display: none;
}

.stepper-main-accordian {
    width: 100%;
    display: flex;
    text-align: center;
    margin: 44px auto;
    .MuiStepIcon-root.MuiStepIcon-active {
        color: #f44336 !important;
    }
    .MuiStepIcon-root.MuiStepIcon-completed {
        color: #f44336 !important;
    }
}

.accordian-wrapper {
    width: 25%;
    margin: 30px 0 0 5px;
    .MuiPaper-root {
        background: #ff8e33;
        color: #fff;
    }
    .MuiAccordionDetails-root {
        background: #fff;
        height: 400px;
        color: grey;
        box-shadow: #898989;
    }
    .MuiButtonBase-root {
        height: 48px;
    }
    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 25px;
    }
}
.accordion-details {
    width: 100%;
    word-break: break-word;
    text-align: left;
    overflow: auto;
    
    .details-card-wrapper{
        padding-left: 5px;
    }
    .rejection-notes {
        margin: 15px 0;
        display: flex;
        align-items: start;
        .heading-log-details {
            margin-left: 5px;
        }
        .rejection-step{
            margin: 0;
            width: 160px;
            font-weight: 500;
        }

       
        .document-icon {
            font-size: 25px;
            color: #191970
        }
        .accordian-details-heading {
            font-size: 12px;
            color: black;
            
            display: flex;
        }
        .log-notes {
            margin: 0;
            font-size: 10px;
            display: flex;
        }
        .log-date {
            margin: 0;
            font-size: 10px;
            color: grey;
            text-align: end;
            width: 75px;
        }
    }
}

::-webkit-scrollbar { 
    width: 0px;
}
.stepper-and-form{
    width: 75%;         
}