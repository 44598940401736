.add-product-layout {
    padding: 30px;
    background: #fbfbfb;
    .addproducts-table-header {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 60px;
        background-color: #f5f5f5;
        box-shadow: 0px 1px 5px #00000029;
        border: 1px solid #f5f5f5;
        border-radius: 5px 5px 0px 0px;
        margin: 30px 0 0;
        padding: 0 20px;
        p {
            color: #090a0c;
            font-family: 'Lato';
            font-size: 14px;
            font-weight: 800;
        }
        span {
            font-size: 11px;
            color: #bebebe;
        }
        .search-input-field {
            display: flex;
            align-items: center;
            width: 240px;
            height: 28px;
            border: 1px solid #d0c9c9;
            background: #fff;
            border-radius: 3px;
            justify-content: space-around;
            .search-ic {
                color: #7e7e7e;
                width: 18px;
            }
            input {
                border: 0;
                width: 85%;
                outline: 0;
            }
            ::placeholder {
                color: #b8b8b8;
                font-family: 'Lato';
                font-size: 11px;
            }
        }
        .brand-input-field {
            display: flex;
            align-items: center;
            width: 180px;
            height: 28px;
            border: 1px solid #d0c9c9;
            background: #fff;
            border-radius: 3px;
            justify-content: space-around;
        
            input {
                border: 0;
                width: 85%;
                outline: 0;
            }
            ::placeholder {
                color: #b8b8b8;
                font-family: 'Lato';
                font-size: 11px;
            }
        }
    }
    .inventory-products-table {
        position: relative;
        border: 1px solid #f5f5f5;
        background-color: #ffffff;
        box-shadow: 0px 1px 5px #00000029;
        // th:nth-child(1) {
        //     width: 300px;
        //     text-align: center;
        // }

        .product-col {
            display: flex;

            .pimg-wrap {
                width: 65px;
                height: 65px;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                margin-right: 19px;
                img.product-img {
                    width: auto;
                    height: 50px;
                    object-fit: contain;
                }
            }

            .pcontent-wrap {
                display: flex;
                align-items: center;
                justify-content: left;
                line-height: 20px;
                word-break: break-word;
                color: #121212;
            }
        }
        .status-col {
            .badge-wrap {
                display: inline-flex;
                padding: 4px 10px;
                background: #f2fff2;
                border: 0.5px solid #41b13d;
                border-radius: 100px;
                color: #606060;
                font-size: 11px;
            }
        }
        .map-locations-column{
            cursor: pointer;
        }
    }
    .all-products-pagination {
        margin-top: 50px;
        .MuiPagination-ul{
            justify-content: flex-end;
        }
    }
}
