.Button {
    cursor: pointer;
    margin: 0 0 12px;
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding-bottom: calc(0.5em - 1px);
    padding-left: calc(0.75em - 1px);
    padding-right: calc(0.75em - 1px);
    padding-top: calc(0.5em - 1px);
    position: relative;
    vertical-align: top;
}

.CenterAlign {
    top: 25%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -25%);
}

.Overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
}

.Overlay.Show {
    display: block;
}

.Modal {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 55%;
    box-shadow: 0 0 4px 0px rgb(0 0 0 / 15%);
    right: -155vh;
    padding: 0 12px 12px;
    transition: all 0.5s ease-out;
    z-index: 10;
    height: 100vh;
    .header-title-con {
        margin: 0 auto;
    }
    .pimg-wrap {
        position: relative;
        display: flex;
        align-items: center;
        img.product-img {
            width: auto;
            display: inline-block;
            height: 80px;
            object-fit: contain;
            // border: 1px solid #ddd;
        }
        .pcontent-wrap {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 100%;
            margin: 0 auto;
            .side-header-content{
                margin-right: 10px;
                font-weight: 500;
                color: #ff632b;
            }
            .mar-8 {
                margin: 8px 0;
            }
            .product-name {
                font-size: 14px;
                font-weight: 600;
                margin: 15px 0px;
            }
            .product-mrp {
                font-size: 20px;
                font-weight: 600;
                margin: 8px 0;
                
            }
            .product-price{
                display: flex;
                align-items: center;
                padding: 5PX;
                .product-priceicon{
                    color: #ff632b;
                    padding: 6px;
                    
                }
                .product-align{
                    padding-left: 10px;
                .product-Bankoffer{
                    color: black;
                    font-size: 18px;
                    font-weight: 600;
                    padding-right: 5px;
                    font-size: medium;
                    margin: 15px 0px;
                    
                }
                .product-discount{
                    color: #363636;
                    font-size: medium;
                    margin: 15px 0px;
                }
            }
            }

            
        }
    }
}

.Modal.Show {
    right: 0;
}

.Close {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    font-size: 18px;
    display: flex;
    width: 100%;
    margin: 30px 0;
}

.Alert {
    position: fixed;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    top: 0;
    left: 0;
    color: #fff;
    background-image: linear-gradient(90deg, #ff9a8b 0%, #ff6a88 55%, #ff99ac 100%);
    margin: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
}

.Alert:empty {
    padding: 0;
}

.product-details-tabular-view {
    .flex {
        .heading {
            color: #ff632b;
        }
        .flex50 {
            margin: 3px 5px;
        }
    }
}