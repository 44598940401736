.settings-page {
  .settings-page-container {
    display: flex;
    margin: 25px;
    gap: 25px;
    .settings-page-sidemenu {
      width: 250px;
      box-shadow: 0px 5px 5px #00000029;
      border-radius: 10px;
      height: fit-content;
      padding-bottom: 10px;
      background-color: #fff;
      .layout-card-content {
        height: fit-content;
        background: #ffff;
        .select-content {
          display: flex;
          text-decoration: none;
          align-items: center;
          justify-content: left;
          padding: 0 10px;
          height: 36px;
          &.active {
            .select-indicator {
              width: 10px;
              height: 10px;
              border: 1px solid #090a0c;
              border-radius: 50px;
              margin: 10px;
              background: #fb6c00;
            }
            p {
              color: #090a0c;
              font-weight: 600;
            }
          }
          .select-indicator {
            width: 10px;
            height: 10px;
            border: 1px solid #bebebe;
            border-radius: 50px;
            margin: 10px;
          }
          p {
            color: #b8b8b8;
          }
        }
      }
      .layout-Header {
        height: 35px;
        margin: 0;
        box-shadow: 0px 0.5px 1px #00000029;
        background: #0000000d;
        display: flex;
        padding: 8px;
        justify-content: space-around;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        p {
          font-weight: 600;
          color: #090a0c;
          padding: 0 5px;
        }
      }
    }
  }
}

.settings-container {
  .settings-header {
    height: 35px;
    margin: 0;
    box-shadow: 0px 0.5px 1px #00000029;
    background: #0000000d;
    display: flex;
    padding: 8px;
    justify-content: space-around;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 400px;
  }

  .settings-content {
    background-color: #fff;
    padding: 0 10px;
    box-shadow: 0px 0.5px 1px #00000029;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .action-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
}
