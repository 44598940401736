.add-contact-dialog {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .dialog-heading {
        font-size: 16px;
        margin: 10px 0;
        text-align: center;
    }

    .add-contact-form-wrapper {
        .input-label-input-field {
            .input-with-verified-icon {
                border: 1px solid #818181;
                width: 300px;
                svg {
                    display: none;
                }
            }
        }
        .action-button-wrapper {
            margin: 10px 0;
        }
    }
}