.add-contact-form {
  width: 500px;
  margin: 0 auto;
.contact-information {
    width: 200px;
}

.contact-name-error {
  display: flex;
    justify-content: flex-end;
    .error-text{
      text-align: left;
    width: 47%;
    }
}

  .primary-contact-confirmation {
    display: flex;
  }

  .account-title-and-name {
    display: flex;
    margin: 15px 0 0 15px;
    .txt-label {
      font-size: 14px;
      font-family: 'Lato';
      margin-right: 15px;
      color: #898989;
      width: 50%;
      text-align: left;
    }

    .input-selector {
      width: 20%;
      padding: 5px;
      font-family: 'Lato';
      border: 1px solid #8d8d8da8;
      border-radius: 4px;
      outline: transparent;
      margin-right: 10px;
  }
  .name-box {
    padding: 6px;
    border-radius: 2px;
    border: 1px solid #8d8d8da8;
    font-family: 'Lato';
    width: 75%;
    outline: transparent;
  }
    // .MuiFormControl-root:first-child {
    //   width: 20%;
    // }
    // .MuiFormControl-root:nth-child(2) {
    //   width: 80%;
    //   margin-left: 20px !important;
    // }
  }
}
