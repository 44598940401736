
.common-input {
    .MuiInputLabel-formControl {
        font-size: 12.5px;
        font-family: 'Lato';
        &.MuiFormLabel-filled{
            font-size: 14px;
            color: #ff8e33;
        }
        &.Mui-focused {
          font-size: 14px;
          color: #ff8e33;
        }
      }

      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #ff8e33  !important;
        }
}