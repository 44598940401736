.listed-product-layout {
    padding: 30px;
    background: #fbfbfb;
    // height: calc(100vh - 100px);
    // overflow: auto;
    th {
        padding: 20px 10px;
        color: #090a0c;
        font-size: 13px;
        font-weight: 800;
        top: 0px;
        z-index: 1;
        text-align: center;
        font-family: "Lato";
        border: 0;
        background-color: transparent;
    }

    .product-header-btn {
        position: relative;
        display: flex;
        .addproduct-btn-wrapper {
            background: #d9d9d9;
            color: #000000;
            border: 0;
            width: 100px;
            font-family: "Lato";
            height: 30px;
            border-radius: 5px;
            font-weight: 600;
            cursor: pointer;
        }
        .export-btn-wrapper {
            background: transparent;
        }
        .more-action-btn {
            border: 0.5px solid #d9d9d9;
            position: absolute;
            right: 10px;
            display: flex;
            align-items: center;
            width: 140px;
            justify-content: space-around;
        }
        .more-action-hide {
            display: none;
        }
        .more-action-ic {
            transform: rotate(180deg);
        }
        .more-action-dropdown {
            position: absolute;
            right: 10px;
            z-index: 9;
            top: 30px;
            background: #fff7f0;
            width: 105px;
            padding-bottom: 25px;
            padding: 0 15px 25px;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #f5f5f5;
            border-radius: 0px 0px 4px 4px;
        }
        p {
            font-size: 12px;
            color: #090a0c;
            font-weight: bold;
        }
    }
    .Inventory-table-layout {
        .Inventory-table-header {
            display: flex;
            align-items: center;
            justify-content: left;
            height: 40px;
            border: 1px solid #f5f5f5;
            background-color: #ffffff;
            box-shadow: 0px 1px 5px #00000029;
            margin: 30px 0 0;
            padding: 5px 0;
            .products-selector {
                color: #090a0c;
                font-size: 14px;
                margin: 0 5px;
                text-decoration: none;
                p {
                    padding: 8px 22px;
                    color: #818181;
                    font-size: 14px;
                }
                &.active {
                    p {
                        color: #090a0c;
                        border-bottom: 2px solid #ff6522;
                        background: #fff2ed;
                        border-radius: 10px 10px 0px 0px;
                        font-weight: bolder;
                    }
                }
            }
            .listed-products-selector {
                display: flex;
                align-items: center;
                span {
                    color: #8181818a;
                }
                &:nth-child(6) span {
                    display: none;
                }
                
            }
            // .inventory-products-search {
            //     ::-webkit-input-placeholder {
            //         /* Chrome/Opera/Safari */
            //         font-family: 'Lato';
            //         color: #b8b8b8;
            //         font-size: 13px;
            //     }
            //     ::-moz-placeholder {
            //         /* Firefox 19+ */
            //         font-family: 'Lato';
            //     }
            //     .products-search {
            //         width: 348px;
            //         height: 25px;
            //         border: 1px solid #b8b8b8;
            //     }
            // }
            .search-input-field {
                margin: 0 auto;

                display: flex;
                align-items: center;
                width: 240px;
                height: 28px;
                border: 1px solid #d0c9c9;
                background: #fff;
                border-radius: 3px;
                justify-content: space-around;
                .search-ic {
                    color: #7e7e7e;
                    width: 18px;
                }
                input {
                    border: 0;
                    width: 85%;
                    outline: 0;
                }
                ::placeholder {
                    color: #b8b8b8;
                    font-family: "Lato";
                    font-size: 11px;
                }
            }
            .products-filters {
                display: flex;
                align-items: center;
                margin: 0 20px;
                width: 25%;
                justify-content: space-between;
                .status-dropdown {
                    border: 0.5px solid #f5f5f5;
                    background: #fafafa;
                    padding: 5px;
                    margin: 0 15px;
                    color: #818181;
                    font-size: 14px;
                    font-family: "Lato";
                    outline: none;
                }
                .sort-btn {
                    display: flex;
                    align-items: center;
                    background: #fafafa;
                    border: 0.5px solid #f5f5f5;
                    color: #818181;
                    padding: 5px;
                    width: 60px;
                    justify-content: center;
                    .sort-ic {
                        width: 15px;
                        color: #818181;
                    }
                }
                p {
                    color: #090a0c;
                    font-family: "Lato";
                    font-size: 14px;
                    font-weight: 800;
                    padding: 5px 8px;
                }
            }
        }
        .Inventory-table-body {
            width: 100%;

            .inventory-products-table {
                th,
                td {
                    border-right: none;
                    border-left: none;
                }
            }
        }
    }
}

.dropbtn {
    display: inline-flex;
    padding: 4px 10px;
    background: #f2fff2;
    border: 0.5px solid #41b13d;
    border-radius: 100px;
    color: #606060;
    font-size: 11px;
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #3c9e38;
    color: #fff;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: auto;
    min-width: 100px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content p {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    margin: 0;
}

.dropdown p:hover {
    background-color: #ddd;
}

.show {
    display: block;
}
.purchase_drop .listed-product-layout .Inventory-table-layout .Inventory-table-header .listed-products-selector:nth-child(4) span{
    display: block;
}
.purchase_drop .listed-product-layout .Inventory-table-layout .Inventory-table-header{
    margin: 0;
}

.purchase_drop .listed-product-layout {
    padding: 0 30px 30px;
}
.purchase_drop .listed-product-layout .Inventory-table-layout .Inventory-table-header .products-selector p {
    padding: 8px 16px;
}
.purchase_drop .app-test {
     margin: 24px 30px;
}
.addware-popup .MuiBackdrop-root {
    background: #00000080;
}
.addware-popup .MuiDialog-scrollPaper{
    margin-top: 0 !important;
}  
.img-latter {
    display: flex;
}
.img-latter img {
    border: 1px solid #ccc;
    margin-right: 12px;
    padding: 5px 5px;
}
.purchase-order-button {
    position: absolute;
    bottom: 4%;
    right: 0;
    display: flex;
    left: 0;
    justify-content: space-between;
    padding: 0 30px;
}
.btn-action,.name-wrapper1{
    // margin: 0 !important;
}

.Purchase-products-table-popup th {
    background: none;
    border: none ;
    border-bottom: 1px solid #e8e8e8;
}
.Purchase-products-table-popup td{
    background: none;
    border: none ;
}
.po-back{
    background:#fff;
    padding: 0 !important; 
}