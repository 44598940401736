.filter-wrapper {
    height: 50px;
    padding: 3px 10px;
    box-shadow: 0px 1px 5px #00000029;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    .date-range {
      display: flex;
      align-items: center;
      gap: 15px;
      h4 {
        margin: 0;
        color: #818181;
        margin-left: 15px;
      }
      .date-range-picker {
        height: 30px;
        border-radius: 6px;
        border: 1px solid #818181;
      }
    }

  }