.dashboard-link {
  text-decoration: none;
  color: black;

  .dashboard-card {
    border: 1px solid #b7b0b0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    .icon-area {
      width: 25%;
      .circle-layer {
        // background-color: #feecdc;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 50px;
        margin: 10px;
        .dashboard-card-icon {
          font-size: 25px;
          // color: #da873c;
        }
      }
    }
    .count-area {
      width: 75%;
      padding: 0 10px;
      .count-description {
        margin: 0;
        padding: 5px 0;
        font-size: 16px;
      }
      .count {
        margin: 0;
        font-size: 20px;
      }
    }
  }

  .blue {
    .icon-area {
      .circle-layer {
        background-color: #1c64f275;
        .dashboard-card-icon {
          color: #1c64f2;
        }
      }
    }
  }

  .brown {
    .icon-area {
      .circle-layer {
        background-color: #feecdc;
        .dashboard-card-icon {
          color: #da873c;
        }
      }
    }
  }

  .green {
    .icon-area {
      .circle-layer {
        background-color: rgb(110 230 186 / 85%);
        .dashboard-card-icon {
          color: rgb(20 136 31);
        }
      }
    }
  }

  .red {
    .icon-area {
      .circle-layer {
        background-color: #e21010;
        .dashboard-card-icon {
          color: #fff;
        }
      }
    }
  }
}
