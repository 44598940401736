.bank-verification-status {
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;

    .verification-text {
        letter-spacing: 0.5px;
        padding: 0 5px;
        font-size: 14px;
        font-weight: 600;
    }
    .verified {
        color: #1976d2;
    }

    .not-verified {
        color: red;
    }
}

.bank-account-verification-Dialog{
    padding: 0 10px;
    text-align: center;
    width: 400px;
    position: relative;
    .close-icon {
        position: absolute;
        right: 5px;
        top: 3px;
        cursor: pointer;
    }

    .info-text {
        font-size: 14px;
        color: #7d7d7d;
    }
    .dialog-heading {
        font-size: 16px;
        
    }

    .dual-option-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-action, 
        .outlined {
            width: 50%;
        }
    }
}
