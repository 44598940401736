.email-verification-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .email-verification-card {
    height: 400px;
    width: 400px;
    box-shadow: rgb(34 34 34 / 30%) 0px 3px 5px -1px, rgb(34 34 34 / 30%) 0px 6px 10px 0px,
      rgb(34 34 34 / 30%) 0px 1px 18px 0px;
    border-radius: 5px;
    text-align: center;
    img {
      width: 200px;
      margin-top: 15px;
    }

    h1 {
        font-size: 20px;
    font-family: 'Lato';
    text-align: center;
    }

    p {
        padding: 0 20px;
    font-size: 14px;
    font-family: 'Lato';
    line-height: 1.4;
    }

    .btn-action {
        width: 150px;
    }
  }
}
