// .landing-card-wrapper {
//     border: 1px solid transparent;
//     width: 300px;
//     border-radius: 2px;
//     background:transparent;
//     margin-right: 20px;
//     display: flex;
//     border: 1px solid #B8B8B8;
//     border-radius: 5px;
//     cursor: pointer;
// }
// .profile-wrap {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     padding-left: 15px;
//     margin-right: 20px;

//     .avatar-profile {
//         border: 2px solid lightskyblue;
//         width: 80px;
//         height: 80px;
//         border-radius: 100px;
//         display: inline-flex;
//         align-items: center;
//         justify-content: center;
//     }

//     .img-ic {
//         width: 70px;
//         height: 70px;
//         background: lightskyblue;
//         border-radius: 100px;
//         display: inline-flex;
//         align-items: center;
//         justify-content: center;
//         object-fit: cover;
//     }
// }
// .detail-wrap {
//     padding: 10px 10px 10px 0;

//     .title-wrap {
//         color: lightskyblue;
//         margin: 0;
//         margin-bottom: 10px;
//     }
//     .desc-wrap {
//         margin: 0;
//         color: #999;
//     }
// }

.landing-card-wrapper {
    background: transparent;
    margin-bottom: 25px;
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    cursor: pointer;
}

.profile-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    margin-right: 20px;
    .title-wrap {
        font-size: 12px;
        font-weight: 600;
        font-family: 'Lato';
    }

    .avatar-profile {
        border: 2px solid lightskyblue;
        width: 80px;
        height: 80px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .img-ic {
        margin-right: 10px;
        background: #ffe4c4ab
    }
}

.detail-wrap {
    font-size: 10px;
    margin-bottom: 10px;
    margin-left: 15px;

    .desc-wrap {
        margin: 0;
        color: #999;
    }
}
