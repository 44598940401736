.search-and-filter-layout {
    display: block;
    width: 100%;
    .all-products-pagination {
        margin: 50px 0;
        .MuiPagination-ul {
            justify-content: flex-end;
        }
    }

    .search-product-list {
        margin: 0 0 20px 20px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 5px 5px 0px 0px;

        .product-table-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                background: #d9d9d9;
                color: #000000;
                border: 0;
                width: 200px;
                height: 30px;
                border-radius: 5px;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                .btn-text {
                    margin-right: 1rem;
                }
            }
        }

        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #ccc;
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            background: #d9d9d9;
            font-weight: 600;
            color: #000000;
        }

        .file-preview {
            margin: 0 10px;
        }

        .search-product-header {
            margin: 0;
            padding: 12px;
            background: #f5f5f5;

            color: #090a0c;
            font-size: 14px;
            font-weight: bold;
            font-family: "Lato";
            // width: 14.21vw;
        }
        .search-product-layout {
            padding: 12px;
            display: grid;
            // grid-template-columns: 300px 300px 300px;
            grid-template-rows: 250px 80px;
            align-items: end;
            margin-bottom: 5px;
            gap: 5px;

            .button-wrapper-box {
                grid-column: 1/4;
                display: flex;
                justify-content: center;
            }


            .search-container {
                width: 80%;
                p {
                    margin: 0 0 10px 0;
                    font-weight: 700;
                    font-size: 14px;
                    color: #000;
                }

                .search-input-field {
                    border: 1px solid #8b8b8b;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-radius: 3px;
                    box-shadow: 0px 1px 5px #00000029;
                    justify-content: space-around;
                    padding: 0 5px;
                    .search-ic {
                        color: #7e7e7e;
                        width: 18px;
                    }
                    input {
                        border: 0;
                        width: 100%;
                        outline: 0;
                        height: 30px;
                        margin: 0 0 0 10px;
                    }
                    ::placeholder {
                        color: #b8b8b8;
                        font-family: "Lato";
                        font-size: 12px;
                        margin: 0;
                        font-weight: 500;
                        color: #939090;
                    }
                }
            }
            .input-or-wrapper {
                margin: 0;
                color: #bebebe;
                font-size: 11px;
                text-align: center;
                margin: 10px;
            }
            .filter-catergory-wrapper {
                box-shadow: 0px 1px 5px #00000029;
                border: 1px solid #f5f5f5;
                border-radius: 5px;
                background: #ffffff;
                padding: 10px;
                .fiter-catergory {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 25px;
                    text-decoration: none;
                    height: 35px;
                    border-radius: 5px;
                    padding-left: 5px;
                    &.active {
                        // background-color: #f5f5f5;
                        // .keyboard-right-icon {
                        //     color: #000000;
                        // }
                        // p {
                        //     font-weight: bold;
                        // }
                    }
                    p {
                        margin: 0;
                        color: #3b3b3b;
                        font-size: 12px;
                    }
                    .keyboard-right-icon {
                        color: #b8b8b8;
                        width: 20px;
                    }
                }
            }
            .product-search-btn {
                text-align: center;
                margin: 0;
                display: flex;
                width: 25%;
                align-items: flex-end;
                .btn-action {
                    background: #fff2ed;
                    font-family: "Lato";
                    font-size: 15px;
                    outline: transparent;
                    border: none;
                    height: 30px;
                    padding: 0px 15px;
                    color: #fb6c00;
                    border-radius: 3px;
                    cursor: pointer;
                    font-weight: 600;
                    width: 160px;
                    margin: 0 20px;
                    box-shadow: 0px 3px 6px #00000052;
                }
            }
            .product-download-btn {
                text-align: center;
                margin: 70px 0 20px;
                display: flex;
                .btn-action {
                    background: #f25822;
                    font-family: "Lato";
                    font-size: 14px;
                    outline: transparent;
                    border: none;
                    height: 32px;
                    padding: 0px 15px;
                    color: #fff;
                    border-radius: 3px;
                    cursor: pointer;
                    font-weight: 600;
                    width: 160px;
                    box-shadow: 0px 3px 6px #00000052;
                    &:hover {
                        background: #fff2ed;
                        color: #f25822;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .search-product-tbl {
        width: 100%;
        overflow: auto;
        padding-left: 20px;
        border-radius: 5px 5px 0px 0px;
        display: inline-block;
        box-sizing: border-box;

        .product-table-title {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            gap: 30px;
            button {
                background: #59b32466;
                color: green;
                border: 0;
                width: 200px;
                height: 30px;
                border-radius: 5px;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;
                .btn-text {
                    margin-right: 1rem;
                }
            }
        }

        input[type="file"] {
            display: none;
        }
        .custom-file-upload {
            border: 1px solid #61aaff;
            display: inline-block;
            padding: 6px 12px;
            cursor: pointer;
            background: #61aaff;
            font-weight: 500;
            color: #fff;
            border-radius: 5px;
            height: 20px;
            margin: 0 14px;
            font-size: 14px;
        }

        .file-preview {
            margin: 0 10px;
        }
        .export-button-wrapper {
            background: #d9d9d9;
            color: #000000;
            border: 0;
            width: 100px;
            height: 30px;
            border-radius: 5px;
            font-weight: 600;
        }

        table {
            position: relative;
            border: 1px solid #f5f5f5;
            background-color: #ffffff;
            box-shadow: 0px 1px 5px #00000029;
            th,
            td {
                border-right: none;
                border-left: none;
            }
        }

        // th:nth-child(1) {
        //     width: 300px;
        //     text-align: center;
        // }

        .product-col {
            display: flex;
            cursor: pointer;

            .pimg-wrap {
                
                width: 65px;
                height: 65px;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                margin-right: 19px;
                img.product-img {
                    width: auto;
                    height: 50px;
                    object-fit: contain;
                }
            }

            .pcontent-wrap {
                display: flex;
                align-items: center;
                justify-content: left;
                line-height: 20px;
                word-break: break-word;
                color: #121212;
            }
        }
        .status-col {
            .badge-wrap {
                display: inline-flex;
                padding: 4px 10px;
                background: #f2fff2;
                border: 0.5px solid #41b13d;
                border-radius: 100px;
                color: #606060;
                font-size: 11px;
            }
        }
        .map-locations-column {
            cursor: pointer;
            button:disabled,
            button[disabled] {
                border: 1px solid #999999;
                background-color: #cccccc;
                color: #666666;
                font-style: italic;
            }
        }
    }

    .catogeries-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;
        .select-label-wrapper {
            margin: 0;
            font-size: 14px;
            color: #818181;
            width: 120px;
        }
        .MuiTypography-body1 {
            font-size: 15px;
            padding: 2px 10px;
            border-radius: 5px;
        }
    }
}

.add-products-dialog {
    text-align: center;
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        max-width: 800px;
    }
    .dialog-header{
        display: flex;
        align-items: center;
        justify-content: center;
        .product-code-header {
            font-weight: 600;
        }
        .product-code-wrap {
            width: 50%;
            display: flex;
        }
    }
    .dialog-content-wrap {
        display: flex;
        align-items: center; 
        .left-content-dialog{
            position: relative;
            
        }   
    }

    .MuiFormControl-fullWidth {
        width: 60%;
    }

    .dialog-action-btn-wrap{
        
    }
    .products-title {
        text-align: center;
        width: 45%;
        .MuiTypography-root {
            font-size: 15px;
            font-family: "Lato";
            font-weight: 600;
        }
    }
    .btn-action {
        background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 48px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 160px;
        box-shadow: 0px 3px 6px #00000052;
    }
    .outlined {
        // background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 48px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 160px;
        box-shadow: 0px 3px 6px #00000052;
    }
    .pimg-wrap {
        width: 65px;
        height: 65px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        margin-right: 19px;
        img.product-img {
            width: auto;
            height: 180px;
            object-fit: contain;
        }
    }
}
.searchproduct-sort-dialog {
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
        bottom: 0 !important;
    }
}

.custom-dropdown {
    margin: 5px;
    width: 85%;
    p {
        margin: 0 0 10px 0;
        font-weight: 700;
        font-size: 14px;
        color: #000;
    }

    .dropdown-field {
        position: relative;
        border: 1px solid #8b8b8b;
        height: 30px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 20px;
        .selected-item {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: #939090;
        }

        .downside-arrowmark {
            position: absolute;
            right: 0;
            padding-right: 10px;
            color: #8b8b8b;
        }
    }
}

.bulk-actions-wrapper {
    margin: 0 0 20px 0;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 5px 5px 0px 0px;
    .search-product-header {
        margin: 0;
        padding: 12px;
        background: #f5f5f5;
        color: #090a0c;
        font-size: 14px;
        font-weight: bold;
        font-family: "Lato";
    }
}

.bulk-action-text {
    margin: 0;
    padding: 12px;
    color: #090a0c;
    font-size: 14px;
    font-weight: bold;
    font-family: "Lato";
}

.excel-icon {
    font-size: 18px;
    padding: 0 5px 0 0;
    color: green;
}

.upload-icon {
    padding: 0 5px;
}

.bulk-upload-result {
    min-width: 500px !important;
    height: 180px !important;
    .bulk-upload-heading {
        font-size: 16px;
        text-align: center;
    }

    .upload-result {
        .successfull-items {
            color: forestgreen;
            text-align: center;
        }

        .failed-items {
            color: red;
            text-align: center;
        }
    }

    .bulk-upload-buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.search-box-grid, 
.brand-box,
.category-selection-box {
    display: flex;
    height: 250px;
    align-items: flex-start;
}

.category-selection-box {
flex-direction: column;
}