.btn-link {
    background: transparent;
    border: none;
    font-size: 12px;
    font-family: 'Lato';
    cursor: pointer;
    border-bottom: 1px solid #1e2f45;
    padding: 0;
    margin: 0;
    color: #0073d3;
}