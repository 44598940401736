.btn-primary {
    padding: 5px 20px;
    background: #ff632b;
    color: #fff;
    font-family: 'Lato';
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    height: 40px;
    margin: 5px;
}

.btn-primary:hover {
    background: #ff632b !important;
}

.closeIcon {
    cursor: pointer;
}

.dialogTitle {
    flex: unset !important;
    margin: unset !important;
    padding-top: unset !important;
    padding-right: unset !important;
    padding-bottom: unset !important;
    padding-left: 15px !important;
}

.dialogContent {
    padding-bottom: 25px !important;

    text-align: center;
}
.stock-label-content {
    color: #ff632b;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}
.warehouse-dropdown {
    margin: 10px 0;
}

.btn-primary-stock{
    padding: 5px 0px;
    background: #ff632b;
    color: #fff;
    font-family: 'Lato';
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    height: 25px;
    width:140px;
}